<template>
  <div id="productTag">
    <div class="product2">
      <div class="product2-first">
        <div style="width: 8.9rem; margin: auto; display: flex">
          <div class="product2-first-img imgBack1"></div>
          <div class="product2-first-cont">
            <li>
              在线接单，在线输单，输单无须打印出纸质资料，实现无纸化办公。
            </li>
            <li>
              系统自动合成发票资料，自动上传单一窗口，自动派生电子委托上传单一窗口。
            </li>
            <li>
              输好单，合成好资料后，可以批量打印报关单和报关资料，方便审单。
            </li>
            <li>
              修改报关数据，修改记录全部记录在接单系统里，（包括审单员在单一窗口网页修改）方便统计输单。
            </li>
            <li>
              支持客户网页发资料，支持客户发指定邮箱，支持接单系统内部代发资料。
            </li>
            <li>
              对于海关双A 认证，系统可以全流程监控操作，完全支持海关要求。
            </li>
            <li>
              可实现全流程无纸化，快速统计输单员，审单员任意一段时间内，输了多少票，多少个品名，审单同理，是很好的管理工具和KPI考核指标。
            </li>
            <li>做账实现全自动化</li>
            <li>预录入单放行单</li>
            <li>电子委托管理系统</li>
          </div>
        </div>
      </div>
      <div class="product2-first">
        <div style="width: 8.9rem; margin: auto; display: flex">
          <div class="product2-first-img imgBack3"></div>
          <div class="product2-first-cont">
            <li>
              单证中心团队百余人，以打造全国最大的单证服务中心为目标，秉承科技让单证更简单的企业使命，为报关行提供365天、24小时无休的单证服务，为报关企业减负增效。
            </li>
            <li>
              月平均输单审单量超17万票，服务宁波，舟山，嘉兴，台州，义乌，上海，深圳，连云港，太仓，青岛，威海等各大口岸关区，与近300余家大小报关企业以及AEO高级认证企业保持长期友好的合作关系。
            </li>
            <li>
              为报关企业提效降费，让企业有更多的精力和时间去扩大业务、增加营收，强化核心竞争力
            </li>
            <li>
              高效输单服务：提供电子资料制单，不限文件模板和格式，快速高效
            </li>
            <li>
              专业审单服务：精确预录，细致审核，将单证处理至符合海关申报要求，专业精准，解放劳动力
            </li>
          </div>
        </div>
      </div>
      <div class="product2-first">
        <div style="width: 8.9rem; margin: auto; display: flex">
          <div class="product2-first-cont">
            <li>船期订阅，开港截港随时跟踪，减少重复查询</li>
            <li>箱货跟踪，物流进度/海关进度全程跟踪，实时查询，自动预警</li>
            <li>
              HS机器人，智能HS归类机器人，专业高效，精确编码，退税情况，一问便知
            </li>
            <li>舱单平台，全国舱单，智能录入，精准校验,舱单无忧</li>
          </div>
          <div class="product2-first-img imgBack4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Icon: [
        {
          index: 1,
          imgUrl: require("../assets/renzheng@2x.png"),
          title: "海关AEO认证",
        },
        {
          index: 2,
          imgUrl: require("../assets/yewu@2x.png"),
          title: "业务数字化",
        },
        {
          index: 3,
          imgUrl: require("../assets/liucheng@2x.png"),
          title: "流程规范化",
        },
        {
          index: 4,
          imgUrl: require("../assets/gongzuo@2x.png"),
          title: "工作协同化",
        },
        {
          index: 5,
          imgUrl: require("../assets/dashuju@2x.png"),
          title: "大数据共享化",
        },
        {
          index: 6,
          imgUrl: require("../assets/xinxi@2x.png"),
          title: "信息智能化",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.product2-first {
  width: 9.2rem;
  display: flex;
  margin: 1rem auto;
}
.product2-first-img {
  width: 3.28rem;
  height: 3.63rem;
  color: #fff;
  font-size: 0.3rem;
  line-height: 1.2rem;
  text-align: center;
  font-weight: 600;
  margin: auto;
}
.imgBack1 {
  background-image: url(../assets/F1.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.imgBack3 {
  background-image: url(../assets/DZZX.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.imgBack4 {
  background-image: url(../assets/FCW.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.product2-first-cont {
  width: 5.8rem;
  margin: auto;
  margin-left: 0.2rem;
  font-size: 0.24rem;
  line-height: 0.45rem;
}
</style>
