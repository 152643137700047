import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'lib-flexible/flexible';
import 'amfe-flexible';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/index.less';
require('animate.css/animate.min.css');

// 滚动动画 wow.js
import { WOW } from 'wowjs';
Vue.prototype.$wow = new WOW({
    boxClass: 'wow', // default
    animateClass: 'animated', // default
    offset: 150, // default
    mobile: true, // default
    live: false,
});
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
