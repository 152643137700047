<template>
  <div id="home">
    <div class="carousel">
      <el-carousel>
        <el-carousel-item>
          <div class="carousel1">
            <el-image
              :src="HLJImage"
              :preview-src-list="srcList"
              class="imgHei"
              :initial-index="1"
            />
            <el-image
              :src="NBImage"
              :preview-src-list="srcList"
              class="imgNB"
              :initial-index="0"
            />
            <el-image
              :src="KCImage"
              :preview-src-list="srcList"
              class="imgKuche"
              :initial-index="3"
            />
            <el-image
              :src="SCImage"
              :preview-src-list="srcList"
              class="imgShache"
              :initial-index="4"
            />
            <el-image
              :src="CHImage"
              :preview-src-list="srcList"
              class="imgCH"
              :initial-index="2"
            />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="main_swiperOne">
            <el-popover
              placement="top"
              width="300"
              trigger="hover"
              popper-class="poper_cls"
            >
              <slot name="content">
                <div style="color: #5772ff; display: flex; align-items: center;letter-spacing:1px;">
                  <el-image
                    :src="hintIcon"
                    style="width: 20px; height: 20px"
                  ></el-image>
                  此系统为内部系统，如需开通请<a
                    href="#contact_us"
                    style="color: #e36e6e"
                    >联系我们</a
                  >
                </div>
              </slot>
              <div slot="reference" class="swiper_btn">立即体验</div>
            </el-popover>

            <el-image
              :src="orderSecondScreen"
              :preview-src-list="orderImageList"
              class="order_screen_second"
            />
            <el-image
              :src="orderFirstScreen"
              :preview-src-list="orderImageList"
              class="order_screen_first"
            />
            <el-image :src="orderRightIcon" class="order_right_icon" />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="main_swiperTwo">
            <el-image
              class="swiper_btn btn_after"
              :src="swiperBtnBg"
              style="left: 7%"
              :preview-src-list="f1WxCodeList"
            ></el-image>
            <div class="swiper_screen">
              <el-image
                :src="f1FirstScreen"
                :preview-src-list="f1ScreenList"
                class="f1_screen_first"
              />
              <el-image :src="F1Code" class="f1_code" />
              <el-image
                :src="f1SecondScreen"
                :preview-src-list="f1ScreenList"
                class="f1_screen_second"
              />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="main_swiperTree">
            <div
              class="swiper_btn"
              @click="openView('https://fangcangwang.com/')"
              style="left: 7%"
            >
              立即体验
            </div>
            <el-image
              :src="FCWfirstScreen"
              :preview-src-list="fcwImageList"
              class="FCW_screen_first"
            />
            <el-image
              :src="FCWSecondScreen"
              :preview-src-list="fcwImageList"
              class="FCW_screen_second"
            />
            <el-image :src="FCWLocationMark" class="FCW_l_mark" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="divider_box"></div>
    <div class="business screen_height">
      <div class="business-color wow animate__animated animate__bounce">
        核心业务
      </div>
      <div class="english">CORE BUSINESS</div>
      <div class="DetailCont">
        <div class="details" v-for="(item, index) in tabContent" :key="index">
          <div class="contentIcon">
            <div style="margin: 8px; height: 100px">
              <div
                style="
                  font-size: 20px;
                  color: white;
                  margin: 8px 0;
                  font-weight: 700;
                "
              >
                {{ item.title }}
              </div>
              <div style="font-size: 10px; color: white; line-height: 15px">
                {{ item.title1 }}
              </div>
            </div>
            <img
              :src="item.imgUrl"
              alt=""
              style="width: 120px; height: 120px"
            />
          </div>
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 0;
                justify-content: space-around;
              "
            >
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 15px; height: 15px; margin-right: 10px"
                />
                <div class="cont">{{ item.cont.cont1 }}</div>
              </div>
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 15px; height: 15px; margin-right: 10px"
                />
                <div class="cont">{{ item.cont.cont2 }}</div>
              </div>
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 15px; height: 15px; margin-right: 10px"
                />
                <div class="cont">{{ item.cont.cont3 }}</div>
              </div>
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 15px; height: 15px; margin-right: 10px"
                />
                <div class="cont">{{ item.cont.cont4 }}</div>
              </div>
            </div>
            <button
              class="detail_btn"
              @click="ProductCenterClick(item.linkClass)"
            >
              了解详情&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="screen_height add_server">
      <h1 class="business-color">增值工具</h1>
      <p class="english">VALUE ADDED TOOLS</p>
      <div class="specific">
        <div class="specific_item">
          <img src="@/assets/server_robot.png" />
          <div class="item_content">
            <p class="item_name">QQ机器人</p>
            <ul class="cnt_ul">
              <li>Q群帮忙接单，自助查电委、查船期</li>
              <li>24小时甩箱预警，申报推送</li>
              <li>科技前沿，在您身边</li>
            </ul>
          </div>
        </div>
        <div class="specific_item">
          <img src="@/assets/server_robot.png" />
          <div class="item_content">
            <p class="item_name">HS CODE 归类</p>
            <ul class="cnt_ul">
              <li>HS CODE 智能归类不求人</li>
              <li>智能识别,精准归类</li>
              <li>7000万+条历史大数据助力推荐</li>
            </ul>
          </div>
        </div>
        <div class="specific_item">
          <img src="@/assets/server_robot.png" />
          <div class="item_content">
            <p class="item_name">海关AEO高级认证</p>
            <ul class="cnt_ul">
              <li>可视化报关进程,操作留底,规范运营</li>
              <li>电子归档,单证无纸化管理,节省成本,提高效率</li>
              <li>单证复核,避免错审漏审</li>
              <li>风险预警,实时监测潜在风险</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="headLine_list">
      <div class="embed_box wow animate__animated animate__pulse">
        <div
          v-for="(item, index) in productSign"
          :key="index"
          class="embed_box_item"
        >
          <img
            :src="item.imgUrl"
            alt=""
            style="width: 100px; height: 100px; animation: flip 2s"
          />
          <div class="Icon-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="producks screen_height">
      <div>
        <div style="margin: 50px 0">
          <p class="middle_title wow animate__animated animate__bounce">
            我们的优势
          </p>
          <div
            style="
              font-size: 14px;
              font-weight: 500;
              color: #999999;
              text-align: center;
              margin-top: 20px;
            "
          >
            PRODUCKS AND BUSINESS
          </div>
        </div>
        <div class="producks-content">
          <div class="producks-left" style="padding-left: 60px">
            <div
              v-for="(item, index) in producks1"
              :key="index"
              class="producks1"
            >
              <div class="producks-title">{{ item.title }}</div>
              <div class="producks-english">{{ item.english }}</div>
              <div class="producks-cont">{{ item.cont }}</div>
            </div>
          </div>
          <div class="producks-middle">
            <div class="producks-m-bottom"></div>
          </div>
          <div
            class="producks-left"
            style="text-align: right; padding-right: 60px"
          >
            <div v-for="(item, index) in producks2" :key="index">
              <div class="producks-title">{{ item.title }}</div>
              <div class="producks-english">{{ item.english }}</div>
              <div class="producks-cont">{{ item.cont }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seekadvice">
      <div>
        <div style="display: flex; justify-content: space-between">
          <div
            style="font-size: 20px; margin-bottom: 30px; animation: fadeinT 2s"
          >
            为什么选择我们?
          </div>
        </div>
        <div class="team_introduce">
          <div style="margin-bottom: 8px; line-height: 20px">
            深厚的技术积累，优质的产品服务和对行业深入的专业分析及判断，
            结合我司自主研发的专利系统致力并服务于全国各口岸的报关、物流及外贸企业，持续为企业赋能，提效降费，助力企业在行业内不断领先发展。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productSign, tabContent } from "@/utils/common";
import countTo from "vue-count-to";
export default {
  name: "HomeView",
  props: ["OfficialWebsiteIndex"],
  components: { countTo },
  data() {
    return {
      productSign,
      tabContent,
      hintIcon: require("@/assets/hintIcon.png"),
      swiperBtnBg: require("@/assets/swiper_btnBg.png"),
      f1WxCodeList: [require("../assets/productScreen/F1Code2x.png")],
      f1FirstScreen: require("../assets/productScreen/F1First.png"),
      f1SecondScreen: require("../assets/productScreen/F1second.png"),
      F1Code: require("../assets/productScreen/F1Code.png"),
      FCWfirstScreen: require("../assets/productScreen/FCWfirst.png"),
      FCWSecondScreen: require("../assets/productScreen/FCWSecond.png"),
      FCWLocationMark: require("../assets/productScreen/FCWLocationMark.png"),
      fcwImageList: [
        require("../assets/high-definitionImgs/FCWfirst.png"),
        require("../assets/high-definitionImgs/FCWSecond.png"),
      ],
      orderFirstScreen: require("../assets/productScreen/orderFirst.png"),
      orderSecondScreen: require("../assets/productScreen/orderSecond.png"),
      orderRightIcon: require("../assets/productScreen/orderRightIcon.png"),
      orderImageList: [
        require("../assets/high-definitionImgs/orderFirst.png"),
        require("../assets/high-definitionImgs/orderSecond.png"),
      ],
      f1ScreenList: [
        require("../assets/high-definitionImgs/F1First.png"),
        require("../assets/high-definitionImgs/F1second.png"),
      ],
      NBImage: require("../assets/ningbo.png"),
      KCImage: require("../assets/kuche.png"),
      HLJImage: require("../assets/heilongjiang.png"),
      SCImage: require("../assets/shache.png"),
      CHImage: require("../assets/changchun.png"),
      srcList: [
        require("../assets/high-definitionImgs/ningbo.png"),
        require("../assets/high-definitionImgs/heilongjiang.png"),
        require("../assets/high-definitionImgs/changchun.png"),
        require("../assets/high-definitionImgs/kuche.png"),
        require("../assets/high-definitionImgs/shache.png"),
      ],
      producks1: [
        {
          index: 1,
          title: "流程规范化",
          english: "Process Standardization",
          cont: "业务规范，数据交换规范",
        },
        {
          index: 2,
          title: "工作协同化",
          english: "Work Collaboration",
          cont: "打破孤岛，协同链接",
        },
        {
          index: 3,
          title: "信息智能化",
          english: "Information Intelligence",
          cont: "挖掘数据关联，智能化服务业务数字化",
        },
      ],
      producks2: [
        {
          index: 1,
          title: "业务数字化",
          english: "Business Digitization",
          cont: "无纸化操作，数据可视化",
        },
        {
          index: 2,
          title: "大数据共享化",
          english: "Biq data Sharing",
          cont: "流程内共享，私密化服务",
        },
        {
          index: 3,
          title: "海关AEO认证",
          english: "Customs AEO Certification",
          cont: "全流程监控，助力AEO认证",
        },
      ],
    };
  },
  computed: {
    endSign: function () {
      return (index) => {
        let pre = "";
        switch (index) {
          case 0:
            pre = "+";
            break;
          case 1:
            pre = "%";
            break;
          case 2:
            pre = "%";
            break;
          case 3:
            pre = "万";
            break;
          default:
            "%";
        }
        return pre;
      };
    },
  },
  methods: {
    ProductCenterClick(className) {
      var element = document.querySelector(className);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    formatter: function (num) {
      return num.toFixed(2);
    },
    openView(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="less">
.el-carousel__indicators > .is-active .el-carousel__button {
  background-color: #d12a2a !important;
}
.poper_cls {
  background: #f1f4ff !important;
}
</style>
<style scoped lang="less">
@media screen and (min-width: 1600px) {
  #headLine_list {
    display: flex;
    justify-content: center;
    height: 150px;
    margin: 0 15%;
    box-shadow: 1px 1px 10px #e7ecf3;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 10%,
      #ffffff 44%,
      #ffffff 100%
    );
    .embed_box {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .embed_box_item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .Icon-title {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 700;
      color: #c20d0e;
    }
  }
  .swiper_screen {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 5%;
  }
  .f1_screen_first {
    position: absolute;
    z-index: 100;
    top: 10%;
    left: 5%;
    cursor: pointer;
  }
  .f1_screen_second {
    position: absolute;
    z-index: 50;
    top: 60%;
    left: 20%;
    cursor: pointer;
  }
  .f1_code {
    position: absolute;
    z-index: 50;
    top: 20%;
    left: 45%;
    cursor: pointer;
  }
  .FCW_screen_first {
    position: absolute;
    z-index: 50;
    top: 25%;
    right: 15%;
    cursor: pointer;
  }
  .FCW_l_mark {
    position: absolute;
    z-index: 60;
    top: 45%;
    right: 32%;
  }
  .FCW_screen_second {
    position: absolute;
    z-index: 60;
    top: 56%;
    right: 19%;
    cursor: pointer;
  }
  .order_screen_first {
    position: absolute;
    z-index: 50;
    top: 25%;
    right: 15%;
    cursor: pointer;
  }
  .order_screen_second {
    position: absolute;
    z-index: 40;
    top: 10%;
    right: 20%;
    cursor: pointer;
  }
  .order_right_icon {
    position: absolute;
    z-index: 50;
    top: 8%;
    right: 2%;
  }
  .swiper_btn {
    width: 20%;
    height: 8%;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(@/assets/swiper_btnBg.png);
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: 100;
    top: 65%;
    left: 5%;
    cursor: pointer;
    font-weight: 700;
  }
  .btn_after::after {
    content: "立即体验";
    position: absolute;
    top: 30%;
    left: 35%;
    color: white;
    font-size: 24px;
    font-weight: 700;
  }
  .divider_box {
    height: 60px;
    background-image: url(../assets/redback.png);
    background-size: contain;
  }
  .box {
    width: 0.43rem;
    height: 0.43rem;
    background-color: #a61414;
    margin-top: 0.1ren;
  }
  .carousel {
    /deep/.el-carousel__indicators {
      .el-carousel__button {
        background-color: #d6dae4;
        height: 8px;
        border-radius: 5px;
      }
    }
    /deep/.el-carousel__container {
      height: 700px;
    }
  }
  .carousel1 {
    height: 700px;
    background-image: url(../assets/dituback.png);
    background-size: 100% 100%;
    position: relative;
  }
  .imgHei {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 13%;
    right: 3%;
  }
  .imgHei:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgCH {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 39%;
    right: 4%;
  }
  .imgCH:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgNB {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 77%;
    right: 7%;
  }
  .imgNB:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgKuche {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 12%;
    right: 35%;
  }
  .imgKuche:hover {
    transform: translateY(40px) scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgShache {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 24%;
    right: 51%;
  }
  .imgShache:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .business {
    background-image: url(../assets/businessBack.png);
    background-size: cover;
    padding: 30px 15%;
  }
  .business-color {
    font-size: 28px;
    font-weight: 800;
    color: #333333;
    text-align: center;
    margin: 10px 0;
    animation: flip 2s;
  }
  .english {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    margin: 10px 0;
  }
  .content {
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #999999;
    text-align: center;
    margin: 10px auto;
  }
  .content:hover {
    color: #a61414;
  }
  .DetailCont {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }
  .details {
    width: 32%;
    background: #ffffff;
    height: 350px;
    position: relative;
  }
  .details:hover {
    transform: translateY(-5px); /* 向上偏移一定距离 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    box-shadow: 0.05rem 0.05rem 0.05rem rgba(136, 158, 190, 0.25);
  }
  .contentIcon {
    height: 35%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px;
    background: linear-gradient(131deg, #f27574, #cd252d, #cd252d);
    box-shadow: 0px 0.025rem 0.11rem 0px rgba(150, 6, 6, 0.25);
    position: relative;
  }
  .contentIcon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/threeBridgeBg.png"); /* 设置伪元素的背景图像 */
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contentIcon:hover {
    & img {
      animation: bounce 1.5s ease-out infinite;
    }
  }

  .duiIcon {
    width: 300px;
    display: flex;
    margin-top: 10px;
    padding-left: 50px;
  }
  .pro_cont {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
  }
  .detail_btn {
    width: 80%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    border: #a61414 1px solid;
    color: #a61414;
    background-color: #ffffff;
    position: absolute;
    bottom: 20px;
    font-weight: 900;
    cursor: pointer;
  }
  .detail_btn:hover {
    border: #fd7d54 0.005rem solid;
    background: linear-gradient(146deg, #fd7d54 0%, #ce2b1a 56%, #e5795e 100%);
    color: #fff;
  }
  .producks {
    background-image: url(../assets/producks@2x.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 15%;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    .middle_title {
      text-align: center;
      font-size: 28px;
      font-weight: 800;
      animation: flip 2s ease-out forwards;
    }
  }
  .producks-content {
    margin: auto;
    display: flex;
    justify-content: space-between;
    .producks-middle {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 0;
    }
  }
  .producks-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .producks-title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #242b32;
  }
  .producks-english {
    font-size: 16px;
    margin-bottom: 8px;
    color: #242b32;
  }
  .producks-cont {
    font-size: 16px;
    font-weight: 400;
    color: #242b32;
    line-height: 20px;
  }
  .producks-m-bottom {
    width: 408px;
    height: 328px;
    text-align: center;
    background-image: url(../assets/and@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .seekadvice {
    background-image: url(../assets/chooseBack.png);
    background-size: contain;
    color: #fff;
    min-height: 150px;
    padding: 20px 15% 20px 340px;
    display: flex;
    align-items: center;
    position: relative;
    .team_introduce {
      font-size: 16px;
      animation: fadeinT 3s;
    }
  }
  .add_server {
    padding: 50px 15%;
    .specific {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 100px;
      .specific_item {
        width: 30%;
        min-height: 350px;
        background-image: url(@/assets/server_bg.png);
        background-size: cover;
        border-radius: 10px;
        position: relative;
        color: white;
        .item_content {
          width: 100%;
          position: absolute;
          z-index: 10;
          height: 55%;
          bottom: 0px;
          background-image: url(@/assets/server_cont_bg.png);
          background-size: cover;
          border-radius: 10px;
          transition: transform 1s, height 1s;
          .cnt_ul {
            list-style-type: disc !important;
            font-size: 16px;
            padding-left: 30px;
            margin-top: 20px;
            li {
              width: 80%;
              margin: 10px auto 0;
              padding-left: 20px;
              line-height: 18px;
            }
          }
        }
        .item_name {
          font-size: 18px;
          text-align: center;
          margin-top: 20px;
        }
        img {
          position: absolute;
          top: 15%;
          left: 0;
          right: 0;
          margin: auto;
          width: 100px;
          height: 100px;
          z-index: 20;
        }
      }
      .specific_item:hover {
        img {
          transform: translateY(-30px) scale(1.1);
          transition: transform 1s ease-in-out;
        }
        .item_name {
          transform: translateY(5px);
          transition: transform 1s ease-in-out;
        }
        .item_content {
          height: 70%;
          .cnt_ul {
            transform: translateY(10px);
            transition: transform 1s ease-in-out;
            li {
              width: 70%;
              transform: translateY(20px);
              transition: transform 1s ease-in-out;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 960px) and (max-width: 1600px) {
  #headLine_list {
    display: flex;
    justify-content: center;
    height: 150px;
    margin: 0 10%;
    box-shadow: 1px 1px 10px #e7ecf3;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 10%,
      #ffffff 44%,
      #ffffff 100%
    );
    .embed_box {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .embed_box_item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .Icon-title {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 700;
      color: #c20d0e;
    }
  }
  .swiper_screen {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 5%;
  }
  .f1_screen_first {
    position: absolute;
    z-index: 100;
    top: 10%;
    left: 0%;
    cursor: pointer;
  }
  .f1_screen_second {
    position: absolute;
    z-index: 50;
    top: 60%;
    left: 18%;
    cursor: pointer;
  }
  .f1_code {
    position: absolute;
    z-index: 50;
    top: 20%;
    left: 40%;
    cursor: pointer;
  }
  .FCW_screen_first {
    position: absolute;
    z-index: 50;
    top: 25%;
    right: 5%;
    cursor: pointer;
  }
  .FCW_l_mark {
    position: absolute;
    z-index: 60;
    top: 45%;
    right: 20%;
  }
  .FCW_screen_second {
    position: absolute;
    z-index: 60;
    top: 56%;
    right: 3%;
    cursor: pointer;
  }
  .order_screen_first {
    width: 700px;
    height: 600px;
    position: absolute;
    z-index: 50;
    top: 20%;
    right: 15%;
    cursor: pointer;
  }
  .order_screen_second {
    width: 700px;
    height: 600px;
    position: absolute;
    z-index: 40;
    top: 5%;
    right: 8%;
    cursor: pointer;
  }
  .order_right_icon {
    width: 250px;
    height: 350px;
    position: absolute;
    z-index: 50;
    top: 23%;
    right: 2%;
  }
  .swiper_btn {
    width: 20%;
    height: 8%;
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(@/assets/swiper_btnBg.png);
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: 100;
    top: 65%;
    left: 5%;
    cursor: pointer;
    font-weight: 700;
  }
  .btn_after::after {
    content: "立即体验";
    position: absolute;
    top: 30%;
    left: 35%;
    color: white;
    font-size: 24px;
    font-weight: 700;
  }
  .divider_box {
    height: 60px;
    background-image: url(../assets/redback.png);
    background-size: contain;
  }
  .carousel {
    /deep/.el-carousel__indicators {
      .el-carousel__button {
        background-color: #d6dae4;
        height: 8px;
        border-radius: 5px;
      }
    }
    /deep/.el-carousel__container {
      height: 700px;
    }
  }
  .carousel1 {
    height: 700px;
    background-image: url(../assets/dituback.png);
    background-size: 100% 100%;
    position: relative;
  }
  .imgHei {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 13%;
    right: 3%;
  }
  .imgHei:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgCH {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 39%;
    right: 4%;
  }
  .imgCH:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgNB {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 77%;
    right: 7%;
  }
  .imgNB:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgKuche {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 12%;
    right: 35%;
  }
  .imgKuche:hover {
    transform: translateY(40px) scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .imgShache {
    width: 230px;
    height: 100px;
    position: absolute;
    top: 24%;
    right: 51%;
  }
  .imgShache:hover {
    transform: scale(2.4);
    transition: transform 0.5s ease-in-out;
  }
  .business {
    background-image: url(../assets/businessBack.png);
    background-size: cover;
    padding: 30px 10%;
  }
  .business-color {
    font-size: 28px;
    font-weight: 800;
    color: #333333;
    text-align: center;
    margin: 10px 0;
    animation: flip 2s;
  }
  .english {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    margin: 10px 0;
  }
  .content {
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #999999;
    text-align: center;
    margin: 10px auto;
  }
  .content:hover {
    color: #a61414;
  }
  .DetailCont {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }
  .details {
    width: 32%;
    background: #ffffff;
    height: 350px;
    position: relative;
  }
  .details:hover {
    transform: translateY(-5px); /* 向上偏移一定距离 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    box-shadow: 0.05rem 0.05rem 0.05rem rgba(136, 158, 190, 0.25);
  }
  .contentIcon {
    height: 35%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background: linear-gradient(131deg, #f27574, #cd252d, #cd252d);
    box-shadow: 0px 0.025rem 0.11rem 0px rgba(150, 6, 6, 0.25);
    position: relative;
  }
  .contentIcon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/threeBridgeBg.png"); /* 设置伪元素的背景图像 */
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contentIcon:hover {
    & img {
      animation: bounce 1.5s ease-out infinite;
    }
  }

  .duiIcon {
    width: 100%;
    display: flex;
    margin-top: 10px;
  }
  .pro_cont {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
  }
  .detail_btn {
    width: 80%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    border: #a61414 1px solid;
    color: #a61414;
    background-color: #ffffff;
    position: absolute;
    bottom: 20px;
    font-weight: 900;
    cursor: pointer;
  }
  .detail_btn:hover {
    border: #fd7d54 0.005rem solid;
    background: linear-gradient(146deg, #fd7d54 0%, #ce2b1a 56%, #e5795e 100%);
    color: #fff;
  }
  .producks {
    background-image: url(../assets/producks@2x.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 10%;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    .middle_title {
      text-align: center;
      font-size: 28px;
      font-weight: 800;
      animation: flip 2s ease-out forwards;
    }
  }
  .producks-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .producks-middle {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 0;
    }
  }
  .producks-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .producks-title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #242b32;
  }
  .producks-english {
    font-size: 16px;
    margin-bottom: 8px;
    color: #242b32;
  }
  .producks-cont {
    font-size: 16px;
    font-weight: 400;
    color: #242b32;
    line-height: 20px;
  }
  .producks-m-bottom {
    width: 330px;
    height: 328px;
    text-align: center;
    background-image: url(../assets/and@2x.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .seekadvice {
    background-image: url(../assets/chooseBack.png);
    background-size: contain;
    color: #fff;
    min-height: 150px;
    padding: 20px 15%;
    display: flex;
    align-items: center;
    position: relative;
    .team_introduce {
      font-size: 16px;
      animation: fadeinT 3s;
    }
  }
  .add_server {
    padding: 50px 10%;
    .specific {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 80px;
      .specific_item {
        width: 30%;
        min-height: 350px;
        background-image: url(@/assets/server_bg.png);
        background-size: cover;
        border-radius: 10px;
        position: relative;
        color: white;
        .item_content {
          width: 100%;
          position: absolute;
          z-index: 10;
          height: 55%;
          bottom: 0px;
          background-image: url(@/assets/server_cont_bg.png);
          background-size: cover;
          border-radius: 10px;
          transition: transform 1s, height 1s;
          .cnt_ul {
            list-style-type: disc !important;
            font-size: 16px;
            padding-left: 30px;
            margin-top: 20px;
            li {
              width: 80%;
              margin: 10px auto 0;
              padding-left: 20px;
            }
          }
        }
        .item_name {
          font-size: 18px;
          text-align: center;
          margin-top: 20px;
        }
        img {
          position: absolute;
          top: 15%;
          left: 0;
          right: 0;
          margin: auto;
          width: 100px;
          height: 100px;
          z-index: 20;
        }
      }
      .specific_item:hover {
        img {
          transform: translateY(-30px) scale(1.1);
          transition: transform 1s ease-in-out;
        }
        .item_name {
          transform: translateY(5px);
          transition: transform 1s ease-in-out;
        }
        .item_content {
          height: 70%;
          .cnt_ul {
            transform: translateY(10px);
            transition: transform 1s ease-in-out;
            li {
              width: 70%;
              transform: translateY(20px);
              transition: transform 1s ease-in-out;
            }
          }
        }
      }
    }
  }
}
</style>
