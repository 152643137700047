<template>
  <div id="aboutWe">
    <div class="screen_height aboutWe">
      <div class="about_left">
        <div class="header_title">
          <span style="color: #f13939; margin-right: 10px">关于</span
          ><span style="color: white">我们</span>
        </div>
        <div style="font-size: 15px; color: #f13939; margin-top: 20px">
          About us
        </div>
        <div class="about_detail">
          <p></p>
          <div class="title_detail">
            我司致力于服务全国各口岸的报关、物流及外贸企业。通过科技手段为企业赋能，提效降费。我司自主研发的专利及各知识产权多达30项，开发的产品项目有单一窗口辅助工具、F1报关ERP系统、工单系统、报关数据一键录入、放舱网等，受到了广大客户的一致好评。目前公司拥有市场推广、客服、技术研发，单证中心等团队近百人，同时在新疆、黑龙江、吉林、辽宁等地建立了单证中心和客服团队，不仅让我们的服务有更好地延伸和拓展，同时也为当地的人才培养、就业等提供了强大的支持。
          </div>
        </div>
        <div class="about_detail">
          <p></p>
          <div class="title_detail">
            公司月平均输单审单量超17万票，服务宁波，嘉兴，台州，义乌，舟山，上海，青岛，威海等地，与近300余家报关行及物流公司保持长期友好的合作关系，我司以打造全国最大的单证服务中心为目标，秉承科技让单证更简单的企业使命，以科技创新、优质服务、诚信高效为理念，不断完善系统、提高技能，服务客户，为物流及报关行业持续输血，共同创造更大的价值。
          </div>
        </div>
      </div>
    </div>
    <div class="company2" id="company_state">
      <div class="company2-left">
        <div class="company2-left-title">
          公司<span style="color: #f13939; margin-left: 10px">最新动态</span>
        </div>
        <div class="company2-left-cont">科技让单证更简单</div>
        <div></div>
      </div>
      <div class="company2-right">
        <div
          class="company2-right-second back"
          v-for="(item, index) in second"
          :key="index"
        >
          <div class="second-time">
            <div class="company2-right-month">{{ item.month }}</div>
            <div class="company2-right-year">{{ item.year }}</div>
          </div>
          <div class="second-cont">
            {{ item.cont }}
          </div>
        </div>
      </div>
    </div>
    <div class="develop_view screen_height">
      <div class="head"></div>
      <div class="transition_view">
        <div v-for="(item, index) in courseData" :key="index">
          <div
            class="chunk"
            :style="chunkPosition(item, index)"
            @click="handleClickYear(index)"
          >
            {{ item.year }}
          </div>
        </div>
        <img src="@/assets/historyImg/flowCurve.png" class="center_line" />
      </div>
      <div class="chunk_detail">
        <h1 class="d_title">{{ courseData[active].title }}</h1>
        <p class="d_tip">
          -<span style="margin-left: 5px">{{ courseData[active].year }}</span>
        </p>
        <p class="d_content">{{ courseData[active].content }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { second,handleCompanyCourse } from '@/utils/common';
export default {
  data() {
    return {
      active: 0,
      second,
      courseData:[]
    };
  },
  computed: {
    chunkPosition: function () {
      return (item, index) => {
        let pos = "";
        switch (item.position) {
          case "top":
            pos = `top:10%;margin-left:${item.margin};`;
            break;
          case "bottom":
            pos = `bottom:10%;margin-left:${item.margin};`;
            break;
        }

        if (this.active === index) {
          pos += `width:60px;height:30px;line-height:30px;font-size:16px;color:#821818;backgroundImage:url(${require("@/assets/historyImg/activeImg.png")});`;
        }
        return pos;
      };
    },
  },
  created(){
    // 获取公司历程信息
    this.courseData = handleCompanyCourse('mobile')
  },
  methods: {
    handleClickYear(index) {
      let detailView = document.getElementsByClassName("chunk_detail");
      this.active = index;
      detailView[0].style.setProperty("animation", "none");
      setTimeout(() => {
        detailView[0].style.setProperty("animation", "fadeinR 1.3s");
      }, 100);
    },
  },
};
</script>
<style scoped lang="less">
.aboutWe {
  background-image: url(@/assets/aboutWeImg/aboutBg.png);
  background-size: contain;
  display: flex;
  padding: 20px 20px;
  margin-top: 30px;
  .about_left {
    overflow-y: auto;
    .header_title {
      font-size: 0.4rem;
      font-weight: 700;
    }
    .about_detail {
      min-height: 200px;
      p {
        width: 50px;
        height: 10px;
        background-color: #f13939;
        margin: 10px 0;
      }
      .title_detail {
        font-size: 0.2rem;
        line-height: 30px;
        color: white;
      }
    }
  }
}
.develop_view {
  background-image: url(@/assets/historyImg/fillBg.png);
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  z-index: 10;
  .head {
    width: 80%;
    height: 10%;
    margin: 3% auto 0;
    background-image: url(@/assets/historyImg/head_title.png);
    background-repeat: no-repeat;
    background-size: 180% 60%;
    background-position: 50% 0%;
  }
  .transition_view {
    height: 30%;
    position: relative;
    z-index: 100;
    margin-top: 2%;
    .chunk {
      width: 60px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      background: url(@/assets/historyImg/chunkBg.png);
      background-size: cover;
      background-repeat: no-repeat;
      color: white;
      font-size: 14px;
      position: absolute;
      z-index: 999;
      border-radius: 5px;
    }
    .center_line {
      height: 20%;
      width: 100%;
      position: absolute;
      top: 44%;
      object-fit: contain;
    }
  }
  .chunk_detail {
    width: 90%;
    margin: auto;
    color: white;
    .d_title {
      margin-top: 2%;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
    }
    .d_tip {
      margin-top: 8%;
      font-size: 18px;
      font-weight: 500;
    }
    .d_content {
      margin-top: 8%;
      max-height: 200px;
      line-height: 20px;
      overflow-y: auto;
    }
  }
}
.company1 {
  width: 100%;
  height: 4.31rem;
  font-size: 0.4rem;
  color: #a90f11;
  padding: 0.3rem 0.74rem;
  font-weight: 600;
}
.company1-title {
  margin-bottom: 0.2rem;
}
.company2 {
  width: 100%;
  background-image: url(../assets/mobilecompany@2x.png);
  background-size: 100%;
}
.company2-left {
  padding: 1rem 0 0 0.85rem;
}
.company2-left-title {
  font-size: 0.38rem;
  font-weight: 800;
  color: #2a3447;
}
.company2-left-cont {
  font-size: 0.24rem;
  font-weight: 800;
  color: #2f3b51;
  margin-top: 0.09rem;
  margin-left: 0.15rem;
}
.company2-right {
  padding: 0.7rem 0 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back {
  width: 9rem;
  height: 1.57rem;
  color: #333333;
  margin-bottom: 0.3rem;
  display: flex;
}
.company2-right-first {
  color: #fff;
  background-image: url(../assets/company2right@2x.png);
  background-size: 9rem 1.57rem;
}
.company2-right-time {
  width: 2.4rem;
  margin: 0.2rem 0 0 0.35rem;
}
.company2-right-month {
  font-size: 0.36rem;
}
.company2-right-year {
  font-size: 0.24rem;
}
.company2-right-cont {
  margin: 0.2rem 0.35rem 0 0;
  font-size: 0.24rem;
}
.company2-right-second {
  background-image: url(../assets/company2fff@2x.png);
  background-size: 9rem 1.57rem;
}
.second-time {
  width: 3rem;
  margin: 0.2rem 0 0 0.3rem;
  color: #bc0c0c;
  font-weight: 600;
}
.second-cont {
  width: 11rem;
  margin: 0.2rem 0.35rem 0 0.35rem;
  font-size: 0.24rem;
  font-weight: 800;
  color: #333333;
  line-height: 0.4rem;
}
</style>
