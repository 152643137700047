<template>
  <div style="position: relative">
    <ul class="ul_tab">
      <a href="#productTag" @click="scrollToTarget('#productTag')"
        ><li class="li_tag">产品中心</li></a
      >
      <a href="#guide_view" @click="scrollToTarget('#guide_view')"
        ><li class="li_tag">放舱网</li></a
      >
      <a href="#aboutWe" @click="scrollToTarget('#aboutWe')"
        ><li class="li_tag">关于我们</li></a
      >
      <a href="#contact_us" @click="scrollToTarget('#contact_us')"
        ><li class="li_tag">联系我们</li></a
      >
    </ul>
    <MobileOfficialwebsite></MobileOfficialwebsite>
    <MobileProductcenter></MobileProductcenter>
    <PcFCWView></PcFCWView>
    <MobileCompany></MobileCompany>
    <div class="collaboration">
      <div
        style="
          margin: 0 auto;
          padding: 0.26rem 0;
          width: 3.5rem;
          height: 0.46rem;
          line-height: 0.46rem;
          text-align: center;
        "
      >
        <span style="font-size: 0.42rem; font-weight: 600; color: #cf2b2a"
          >300</span
        >
        <span style="color: #cf2b2a; font-size: 0.42rem; font-weight: 600"
          >+</span
        >
        <span style="font-size: 0.3rem; font-weight: 600; color: #000000"
          >长期合作伙伴</span
        >
      </div>
      <el-carousel indicator-position="none">
        <el-carousel-item v-for="(item, index) in compile" :key="index">
          <div style="width: 5.26rem">
            <img
              :src="item.imgUrl"
              alt=""
              style="height: 0.97rem; width: 5.26rem"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="about" id="contact_us">
      <h1 class="about_title">联系我们</h1>
      <div class="us_list_style">
        <div class="us_item">
          <el-image :src="us_one" class="us_img" />
          <p class="foot_phone">
            <el-image :src="us_phoneIcon" class="phone_icon"></el-image>
            <span class="cell_phone">18644079547</span>
          </p>
        </div>
        <div class="us_item">
          <el-image :src="us_three" class="us_img" />
          <p class="foot_phone">
            <el-image :src="us_phoneIcon" class="phone_icon"></el-image>
            <span class="cell_phone">13157331003</span>
          </p>
        </div>
      </div>
      <div class="consultBtn">立即咨询</div>
    </div>
    <div class="footer">
      <div class="foot_head">
        <div v-for="(item, index) in footList" :key="index" class="head_item">
          <img :src="item.url" style="width: 30px; height: 30px" />
          <div class="item_right">
            <h2>{{ item.title }}</h2>
          </div>
        </div>
      </div>
      <div>
        <div class="foot_content">
          <div class="footer-size">
            <ul>
              <p
                style="
                  font-size: 20px;
                  margin-bottom: 15px;
                  font-family: 'kaiti';
                "
              >
                关于我们
              </p>
              <li class="li_item"><a href="#home">公司简介</a></li>
              <li class="li_item" @click="scrollToTarget('#company_state')">
                公司动态
              </li>
            </ul>
            <ul>
              <p
                style="
                  font-size: 20px;
                  margin-bottom: 15px;
                  font-family: 'kaiti';
                "
              >
                公司产品
              </p>
              <li class="li_item" @click="scrollToTarget('#productTag')">
                F1接单输单系统
              </li>
              <li class="li_item" @click="scrollToTarget('#productTag')">
                数据中心
              </li>
              <li class="li_item" @click="scrollToTarget('#productTag')">
                放舱网
              </li>
            </ul>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              width: 35%;
              justify-content: space-between;
            "
          >
            <div
              style="
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
              "
            >
              <p class="codeText">联系方式</p>
              <div class="foot_touch" style="margin-top: 20px">
                <el-image
                  :src="phoneIcon"
                  lazy
                  style="width: 20px; height: 20px"
                ></el-image>
                <div style="margin-left: 5px">
                  <p>0574-27682901</p>
                  <p>0574-27836910</p>
                </div>
              </div>
              <div class="foot_touch">
                <el-image
                  :src="mobileIcon"
                  lazy
                  style="width: 20px; height: 20px"
                ></el-image>
                <div style="margin-left: 5px">
                  <p>13429380909</p>
                  <p>13685859945</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-extra-info">
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89VpB9nV&recordcode=33021202002094"
            class="footer-line"
            style="margin-right: 8px"
          >
            <span class="footer-info">浙公网安备 33021202002094号</span>
          </a>
          <a href="https://beian.miit.gov.cn/" class="footer-line">
            <span class="footer-info">浙ICP备2022012639号-1</span>
          </a>
        </div>
      </div>
    </div>
    <div class="fixed_pop">
      <el-popover placement="left-start" width="400" trigger="hover">
        <template slot="reference">
          <div class="pop_item">
            <img src="@/assets/phonePopIcon.png" />
            <p>立即咨询</p>
          </div>
        </template>
        <div class="pop_content">
          <div class="pop_content_top">
            <h1 class="pop_title">0574-27682901 0574-27836910</h1>
            <p class="pop_time">7*24小时客服服务热线</p>
          </div>
          <div class="product_view">
            <p>选择下列产品马上在线沟通:</p>
            <ul class="product_list">
              <li>
                <a
                  href="https://wpa.qq.com/msgrd?v=3&uin=1453360582&site=qq&menu=yes&jumpflag=1"
                  >F1接单输单系统</a
                >
              </li>
              <li>
                <a
                  href="https://wpa.qq.com/msgrd?v=3&uin=1453360582&site=qq&menu=yes&jumpflag=1"
                  >放舱网</a
                >
              </li>
              <li>
                <a
                  href="https://wpa.qq.com/msgrd?v=3&uin=1453360582&site=qq&menu=yes&jumpflag=1"
                  >单证中心</a
                >
              </li>
            </ul>
          </div>
        </div>
      </el-popover>
      <div class="pop_item" @click="returnTop">
        <img src="@/assets/returnTop.png" style="width: 25px; height: 25px" />
        <p>回到顶部</p>
      </div>
    </div>
  </div>
</template>
<script>
import MobileOfficialwebsite from "@/views/MobileOfficialwebsite.vue";
import MobileProductcenter from "@/views/MobileProductcenter.vue";
import MobileCompany from "@/views/MobileCompany.vue";
import PcFCWView from "@/views/PcFANGCANGWANGView.vue";
export default {
  components: {
    MobileOfficialwebsite,
    MobileProductcenter,
    MobileCompany,
    PcFCWView,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      activeName: "index",
      compile: [
        {
          imgUrl: require("../assets/company/SHyiao.png"),
        },
        {
          imgUrl: require("../assets/company/SHxinhai.png"),
        },
        {
          imgUrl: require("../assets/company/ydsCompany.png"),
        },
        {
          imgUrl: require("../assets/company/ZSzhongwai.png"),
        },
        {
          imgUrl: require("../assets/company/NBfanyang.png"),
        },
        {
          imgUrl: require("../assets/company/NBwaiyun.png"),
        },
        {
          imgUrl: require("../assets/company/SHzhongwai.png"),
        },
        {
          imgUrl: require("../assets/company/dongnan.png"),
        },
        {
          imgUrl: require("../assets/company/gangxing.png"),
        },
        {
          imgUrl: require("../assets/company/ZJjiulong.png"),
        },
        {
          imgUrl: require("../assets/company/fanzhong.png"),
        },
        {
          imgUrl: require("../assets/company/KSMR.png"),
        },
      ],
      footList: [
        {
          url: require("@/assets/aboutWeImg/footIcon1.png"),
          title: "信息智能化",
          state: "挖掘智能关联,智能化服务",
        },
        {
          url: require("@/assets/aboutWeImg/footIcon2.png"),
          title: "业务数字化",
          state: "无纸化操作,数字可视化",
        },
        {
          url: require("@/assets/aboutWeImg/footIcon3.png"),
          title: "专业服务",
          state: "专业服务全流程支持",
        },
        {
          url: require("@/assets/aboutWeImg/footIcon4.png"),
          title: "365天",
          state: "单证中心无休服务",
        },
      ],
      us_one: require("@/assets/us/us_bigImg_one.jpg"),
      us_three: require("@/assets/us/us_bigImg_three.jpg"),
      us_phoneIcon: require("@/assets/us/us_phoneIcon.png"),
      phoneIcon: require("@/assets/phoneIcon.png"),
      mobileIcon: require("@/assets/mobileIcon.png"),
    };
  },
  methods: {
    returnTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollToTarget(name) {
      let _this = this;
      this.$nextTick(() => {
        setTimeout(() => {
          let company = _this.$el.querySelector(name);
          document.documentElement.scrollTop = company.offsetTop; //苹果滚动
          document.body.scrollTop = company.offsetTop; //安卓滚动
        }, 100);
      });
    },
  },
};
</script>
<style scoped lang="less">
.ul_tab {
  width: 100%;
  background-color: #dbdbdb;
  display: flex;
  justify-content: space-around;
  a {
    color: white;
    text-decoration: none;
  }
  .li_tag {
    padding: 0px !important;
    margin: 0 10px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    display: inline-block;
    list-style: none;
    min-width: 80px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    position: relative;
    text-align: center;
  }
  .li_tag:hover {
    background-color: #999;
  }
}
.fixed_pop {
  width: 60px;
  min-height: 20%;
  background-color: #d92e1b;
  border-radius: 30px;
  position: fixed;
  top: 60%;
  right: 0%;
  z-index: 300;
  box-sizing: border-box;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pop_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px 0;
    img {
      width: 30px;
      height: 30px;
    }
    p {
      margin-top: 5px;
      font-size: 12px;
      color: white;
      opacity: 0.7;
    }
  }
  .pop_item:hover {
    background-color: #c50d1b;
    p {
      opacity: 1;
    }
  }
}
.pop_content {
  box-sizing: border-box;
  .pop_title {
    font-size: 22px;
    color: rgb(238, 185, 85);
  }
  .pop_time {
    font-size: 14px;
    color: #b3b2b2;
  }
}
.pop_content_top {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(224, 224, 224);
}
.product_view {
  margin-top: 20px;
  .product_list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 15px;
    color: #b3b2b2;
    li a {
      color: #999;
      text-decoration: none;
    }
    li a:hover {
      color: #4daad1;
    }
  }
}
.collaboration {
  width: 100%;
  height: 3rem;
  background-image: url(../assets/hezuoback@2x.png);
  background-size: 100% 100%;
  /deep/.el-carousel__container {
    height: 80px;
  }
  /deep/.el-carousel__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.about {
  width: 100%;
  height: 7rem;
  background-image: url(../assets/lianxiback@2x.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  .consultBtn {
    width: 5.06rem;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: #fff;
    font-size: 0.42rem;
    background: #e00f0f;
    box-shadow: 0.04rem 0.03rem 0.05rem 0px rgba(251, 70, 70, 0.25),
      0.04rem 0.04rem 0.07rem 0px rgba(242, 26, 26, 0.25);
    opacity: 1;
    border: 1px solid #e30000;
    border-radius: 0.48rem 0;
    margin: 0.25rem auto 0;
  }
  .about_title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  .us_list_style {
    width: 60%;
    height: 80%;
    min-width: 260px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .us_item {
      width: 30%;
      height: 100%;
      min-width: 120px;
      background-image: url(@/assets/us/contactUs_bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 10px;
      box-shadow: 2px 0px 9px 0px #25549b66;
      .us_img {
        width: 90%;
        height: 50%;
      }
      .foot_phone {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 390px) {
          .cell_phone {
            font-size: 12px;
            font-weight: 600;
            color: #33488f;
          }
        }
        @media screen and (min-width: 391px) {
          .cell_phone {
            font-size: 14px;
            font-weight: 600;
            color: #33488f;
          }
        }
        .phone_icon {
          margin: 0 5px;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
.about-top {
  width: 100%;
  height: 2.5rem;
  padding-top: 0.6rem;
  background-image: url(../assets/lianxiback@2x.png);
  background-size: 100% 3.1rem;
}

.footer {
  background: #141b1e;
  font-size: 16px;
  color: #fff;
  min-height: 290px;
  position: relative;
  .foot_head {
    margin: auto;
    display: flex;
    justify-content: space-around;
    background-color: #1e282d;
    padding: 1% 10%;
    box-sizing: border-box;
    .head_item {
      width: 28%;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .item_right {
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 10px;
        h2 {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .head_item:hover {
      background-color: rgb(36, 35, 35);
    }
  }
  .foot_content {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .codeText {
      font-size: 20px;
      font-family: "kaiti";
    }
    .footer-size {
      display: flex;
      width: 45%;
      justify-content: space-between;
      margin: 0 0 0 5%;
      .li_item {
        opacity: 0.6;
        font-size: 14px;
      }
      ul li {
        margin-top: 12px;
      }
      a {
        color: white;
        text-decoration: none;
      }
      ul li a:hover {
        color: rgb(0, 153, 255);
      }
    }
    .foot_touch {
      margin: 12px 0;
      font-size: 14px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .weixin {
      width: 95px;
      height: 95px;
      margin-top: 20px;
      background-image: url(../assets/erweima.png);
      background-size: cover;
    }
  }

  .footer-extra-info {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .footer-line {
      display: inline-block;
      text-decoration: none;
      height: 20px;
      line-height: 20px;
      &:hover {
        opacity: 0.9;
      }
    }
    .footer-info {
      color: #ffffff;
    }
  }
}
</style>
