export const productSign = [
  {
    index: 1,
    imgUrl: require("../assets/renzheng@2x.png"),
    title: "海关AEO认证",
  },
  {
    index: 2,
    imgUrl: require("../assets/yewu@2x.png"),
    title: "业务数字化",
  },
  {
    index: 3,
    imgUrl: require("../assets/liucheng@2x.png"),
    title: "流程规范化",
  },
  {
    index: 4,
    imgUrl: require("../assets/gongzuo@2x.png"),
    title: "工作协同化",
  },
  {
    index: 5,
    imgUrl: require("../assets/dashuju@2x.png"),
    title: "大数据共享化",
  },
  {
    index: 6,
    imgUrl: require("../assets/xinxi@2x.png"),
    title: "信息智能化",
  },
];
export const tabContent = [
  {
    index: 1,
    imgUrl: require("../assets/f1@2x.png"),
    title: "F1接单输单系统",
    title1: "全流程数字化报关系统",
    cont: {
      imgUrl: require("../assets/dui.png"),
      cont1: "专利技术F1一键录入",
      cont2: "OCR+NLP人工智能",
      cont3: "支持海关AEO高级认证",
      cont4: "提效降费，为行业赋能",
    },
    imgPosition: "left",
    linkClass: ".F1BG",
  },
  {
    index: 2,
    imgUrl: require("../assets/documentCenterIcon.png"),
    title: "数据中心",
    title1: "365天高效优质无休服务",
    cont: {
      imgUrl: require("../assets/dui.png"),
      cont1: "百人团队,专业高效",
      cont2: "提效降费,优化管理",
      cont3: "365x24小时,全年服务",
      cont4: "月均制单量超17万",
    },
    imgPosition: "right",
    linkClass: ".DZBG",
  },
  {
    index: 3,
    imgUrl: require("../assets/releaseIcon.png"),
    title: "放舱网",
    title1: "准确、高效的物流信息追踪和管理平台",
    cont: {
      imgUrl: require("../assets/dui.png"),
      cont1: "供应商多方位择优推广",
      cont2: "全程物流跟踪,物流实时查询",
      cont3: "船期订阅,放舱网小程序实时同步",
      cont4: "助力企业降低物流成本,提升物流效率",
    },
    imgPosition: "left",
    linkClass: ".FCWBG",
  },
];
export const second = [
  {
    year: "2022",
    month: "06-01",
    cont: "火箭科技推出全新产品“放舱网”，为进出口企业和物流公司提供全流程物流跟踪服务，赋能行业发展。",
  },
  {
    year: "2021",
    month: "09-30",
    cont: "单证中心日制单量达到8687票，其中审单1370票，创历史新高。",
  },
  {
    year: "2020",
    month: "03",
    cont: "公司正式推出审单业务。",
  },
  {
    year: "2019",
    month: "01",
    cont: "公司正式推出有自主发明专利技术的F1报关接单输单系统。",
  },
];
const courseData = [
  {
    year: "2015年",
    title: "前身为宁波品宏数据科技有限公司，开始踏入通关科技行业",
    content:
      "宁波火箭数据科技有限公司成立于2018年9月；是一家专业从事与单证服务的专业数据科技公司。致力于服务全国各口岸的进出口贸易、跨境供应链、物流通关企业的科技服务公司，可为企业提供智能化的通关系统及专业化的单证服务。目前公司拥有多项自主知识产权及市场推广客服团队，技术研发团队，报关实验室，单证中心等近百人，单证中心月平均输单审单量17万余票，服务宁波，上海，深圳，舟山，威海，青岛等地，与近300余家报关行及物流公司保持长期友好的合作关系。",
    position: "top",
    margin: "5%",
  },
  {
    year: "2018年",
    title: "宁波火箭数据科技有限公司正式成立。",
    content:
      "我们从宁波品宏数据科技有限公司改名为宁波火箭数据科技有限公司，我们公司正式成立了。",
    position: "bottom",
    margin: "10%",
  },
  {
    year: "2018年",
    title:
      "单一窗口辅助工具推出市场，帮助广大报关行提升工作效率，降低劳动强度。",
    content:
      "单一窗口辅助工具推出市场，帮助广大报关行提升工作效率，降低劳动强度。便捷与实效性：客户端即拷即用，迭代更新周期短。 ②多票批量操作：批量反填/比对，下载/打印预录单、放行通知书、查验单， 上传单证资料，修改提单号码等。 ③自动发单：首创24小时无人值守自动发单。 ④物流跟踪处理：进港/预配查询.件数毛重检查,运抵重发，码头放行等。",
    position: "top",
    margin: "25%",
  },
  {
    year: "2018年",
    title: "建立了全国专业单证中心，提供快速高效优质的输单服务。",
    content: "建立了全国专业单证中心，提供快速高效优质的输单服务。",
    position: "bottom",
    margin: "35%",
  },
  {
    year: "2019年",
    title: "推出了自主发明专利技术F1报关接单输单系统。",
    content:
      // eslint-disable-next-line max-len
      "推出了自主发明专利技术F1报关接单输单系统。①智能单证识别：多样智能信息采集，EXCEL数据批量导入，自动集成表体数据，历史数据运用，辅助信息录入，单证自动合成上传导出。 ②逻辑风险检控：海量逻辑校验，制单风险预警，操作员历史记录，错误可溯源，为企业培训与操作员技能优化提供有效的数据支持。 ③专利技术一键录入：国家发明专利一键录入技术，数据录入速度与准确率相比手工录入大幅度提高。 ④全流程无纸化：线上制单可视化审单，无需扫描仪、打印纸张节约能耗，降低企业费用支出。 ⑤海关AA认证：全流程监控操作，数据记录留存，员工绩效考核。 ⑥单一窗口对接：申报信息直接发送，确保数据真实准确，报关状态实时跟踪。 ⑦对接企业API：可与报关行、货代物流公司对接，实现数据无缝衔接。 ⑧安全性：本地服务器数据信息实时存储，永久保存，自动备份，数据安全性高。",
    position: "top",
    margin: "45%",
  },
  {
    year: "2020年",
    title: "单证中心的服务进一步拓展，与多个口岸城市达成了合作。",
    content:
      "至今为止，单证中心月平均输单审单量17万余票，服务宁波，上海，厦门，舟山，山东等地，与近300余家报关行及物流公司保持长期友好的合作关系。",
    position: "bottom",
    margin: "60%",
  },
  {
    year: "2021年",
    title: "2021年6月成立新疆库车中心",
    content: "2021年6月成立新疆库车中心",
    position: "top",
    margin: "70%",
  },
  {
    year: "2022年",
    title: "2022年12月成立新疆莎车中心",
    content:
      "2022年12月，根据招商局的指示和要求，我们在新疆的莎车建立了目前规模最大的单证中心。结合喀什与浙江地区业务的实际情况，合理利用区域时差，充分利用现代信息化、因特网的优势，充分利用大数据和先进的科技功能，实现业务、人员、系统数据共享与互通理念，优化工作方式，提高工作效率，同时为喀什地区提供一定的就业岗位，有利于促进沿海与内陆的沟通，有利于增进汉维民族间的交流，促进民族团结。",
    position: "bottom",
    margin: "80%",
  },
];
const mobileMargin = ["5%", "10%", "25%", "35%", "45%", "60%", "70%", "80%"];
const pcMargin = ["8%", "20%", "25%", "35%", "45%", "60%", "70%", "80%"];

export const handleCompanyCourse = (type) => {
  let newCourseArr = [];
  if (type === "pc") {
    newCourseArr = courseData.map((item, inx) => {
      return {
        ...item,
        margin: pcMargin[inx],
      };
    });
    return newCourseArr;
  } else {
    newCourseArr = courseData.map((item, inx) => {
      return {
        ...item,
        margin: mobileMargin[inx],
      };
    });
    return newCourseArr;
  }
};
