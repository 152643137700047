<template>
  <div class="radio-group">
    <div id="pc">
      <pc></pc>
    </div>
    <div id="mobile">
      <mobile></mobile>
    </div>
  </div>
</template>

<script>
import pc from "@/components/pc.vue";
import mobile from "@/components/MobileView.vue";
export default {
  name: "HelloWorld",
  components: { pc, mobile },
};
</script>
<style scoped lang="scss">
@media screen and (min-width: 1280px) {
  #pc {
    display: block !important;
  }
  #mobile {
    display: none !important;
  }
}
@media screen and (max-width: 1280px) {
  #pc {
    display: none !important;
  }
  #mobile {
    display: block !important;
    overflow: hidden;
  }
}
</style>
