<template>
  <div id="productTag"> 
    <div class="product_all">
      <div class="product_item">
        <div class="product_item_left F1BG">
          <div class="left_center_box">
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>输单办公无纸化办公</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>一键录入，自动合成发票资料</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>海关双A认证，全流程监控操作</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>为报关行业赋能，提效增质</div>
            </div>
          </div>
        </div>
        <ul class="product_item_right">
          <li>在线接单，在线输单，输单无须打印出纸质资料，实现无纸化办公。</li>
          <li>
            系统自动合成发票资料，自动上传单一窗口，自动派生电子委托上传单一窗口。
          </li>
          <li>
            输好单，合成好资料后，可以批量打印报关单和报关资料，方便审单。
          </li>
          <li>
            修改报关数据，修改记录全部记录在接单系统里，（包括审单员在单一窗口网页修改）方便统计输单。
          </li>
          <li>
            支持客户网页发资料，支持客户发指定邮箱，支持接单系统内部代发资料。
          </li>
          <li>对于海关双A 认证，系统可以全流程监控操作，完全支持海关要求。</li>
          <li>
            可实现全流程无纸化，快速统计输单员，审单员任意一段时间内，输了多少票，多少个品名，审单同理，是很好的管理工具和KPI考核指标。
          </li>
          <li>做账实现全自动化</li>
          <li>预录入单放行单</li>
          <li>电子委托管理系统</li>
        </ul>
      </div>
      <div class="product_item">
        <div class="product_item_left FCWBG">
          <div class="left_center_box">
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>供应商多方位择优推广</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>全程物流跟踪,物流实时查询</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>船期订阅,放舱网小程序实时同步</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>助力企业降低物流成本,提升物流效率</div>
            </div>
          </div>
        </div>
        <ul class="product_item_right">
          <li>
            船期订阅，开港截港随时跟踪，减少重复查询
          </li>
          <li>
            箱货跟踪，物流进度/海关进度全程跟踪，实时查询，自动预警
          </li>
          <li>
            HS机器人，智能HS归类机器人，专业高效，精确编码，退税情况，一问便知
          </li>
          <li>舱单平台，全国舱单，智能录入，精准校验，舱单无忧</li>
        </ul>
      </div>
      <div class="product_item">
        <div class="product_item_left DZBG">
          <div class="left_center_box">
            <div class="img_title">
              <img src="../assets/dui.png" />
              <div>百余人单证中心</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>专业输单系统培训</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>365天高效优质无休服务</div>
            </div>
            <div class="img_title">
              <img src="../assets/dui.png" alt="" />
              <div>月平均输单审单量17万余票</div>
            </div>
          </div>
        </div>
        <ul class="product_item_right">
          <li>
            单证中心团队百余人，以打造全国最大的单证服务中心为目标，秉承科技让单证更简单的企业使命，为报关行提供365天、24小时无休的单证服务，为报关企业减负增效。
          </li>
          <li>
            月平均输单审单量超17万票，服务宁波，舟山，嘉兴，台州，义乌，上海，深圳，连云港，太仓，青岛，威海等各大口岸关区，与近300余家大小报关企业以及AEO高级认证企业保持长期友好的合作关系。
          </li>
          <li>为报关企业提效降费，让企业有更多的精力和时间去扩大业务、增加营收，强化核心竞争力</li>
          <li>高效输单服务：提供电子资料制单，不限文件模板和格式，快速高效</li>
          <li>专业审单服务：精确预录，细致审核，将单证处理至符合海关申报要求，专业精准，解放劳动力</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1600px) {
  .product_all {
    overflow: hidden;
  }
  .product_item {
    margin: 0 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    overflow: hidden;
    padding: 10px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .product_item_left {
      width: 35%;
      height: 500px;
      color: gray;
      padding-top: 100px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .img_title {
        width: 100%;
        padding-left: 10%;
        font-size: 18px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
      h1 {
        font-size: 25px;
        margin-bottom: 20px;
        font-weight: 700;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .F1BG {
      background-image: url(@/assets/F1.png);
      background-size: contain;
    }
    .FCWBG {
      background-image: url("@/assets/FCW.png");
      background-size: contain;
    }
    .DZBG {
      background-image: url("@/assets/DZZX.png");
      background-size: contain;
    }
    .product_item_right {
      list-style: disc;
      flex: 1;
      margin-left: 30px;
      font-size: 18px;
      color: gray;
      line-height: 50px;
    }
  }
  .product_item:hover {
    transform: translateY(-10px);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13),
      0px 0px 5px 0px rgba(214, 30, 27, 0.51),
      inset 0px 2px 2px 0px rgba(255, 255, 255, 0.3);
  }
}
@media screen and (min-width: 960px) and (max-width: 1600px) {
  .product_all {
    overflow: hidden;
  }
  .product_item {
    margin: 0 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
    overflow: hidden;
    padding: 10px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .product_item_left {
      width: 35%;
      height: 500px;
      color: gray;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .left_center_box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 15%;
      }
      .img_title {
        width: 100%;
        padding-left: 5%;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
      h1 {
        font-size: 25px;
        margin-bottom: 20px;
        font-weight: 700;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .F1BG {
      background-image: url(@/assets/F1.png);
      background-size: contain;
    }
    .FCWBG {
      background-image: url("@/assets/FCW.png");
      background-size: contain;
    }
    .DZBG {
      background-image: url("@/assets/DZZX.png");
      background-size: contain;
    }
    .product_item_right {
      list-style: disc;
      flex: 1;
      margin-left: 30px;
      font-size: 18px;
      color: gray;
      line-height: 50px;
    }
  }
  .product_item:hover {
    transform: translateY(-10px);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13),
      0px 0px 5px 0px rgba(214, 30, 27, 0.51),
      inset 0px 2px 2px 0px rgba(255, 255, 255, 0.3);
  }
}
</style>
