<template>
  <div class="collaboration">
    <div
      style="
        padding: 10px 0;
        width: 700px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <p
        style="
          font-size: 50px;
          font-weight: 700;
          color: #cf2b2a;
          animation: bounce 1s;
        "
      >
        300+
      </p>
      <span style="font-size: 40px; font-weight: 600; color: #000000"
        >长期合作伙伴</span
      >
    </div>
    <vue-seamless-scroll
      :data="imgList1"
      :class-option="optionCustomer"
      class="swiper_view"
    >
      <ul class="ul-item">
        <li class="li-item" v-for="(item, index) in imgList1" :key="index">
          <img :src="item.src" style="height: 100%; width: 100%" />
        </li>
      </ul>
      <ul class="ul-item">
        <li class="li-item" v-for="(item, index) in imgList2" :key="index">
          <img :src="item.src" style="height: 100%; width: 100%" />
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      optionCustomer: {
        limitMoveNum: 2,
        direction: 3,
      },
      imgList1: [
        {
          src: require("@/assets/company/SHyiao.png"),
        },
        {
          src: require("@/assets/company/ydsCompany.png"),
        },
        {
          src: require("@/assets/company/KSMR.png"),
        },
        {
          src: require("@/assets/company/NBwaiyun.png"),
        },
        {
          src: require("@/assets/company/ZSzhongwai.png"),
        },
        {
          src: require("@/assets/company/NBfanyang.png"),
        },
        {
          src: require("@/assets/company/SHxinhai.png"),
        },
        {
          src: require("@/assets/company/SHzhongwai.png"),
        },
        {
          src: require("@/assets/company/ZJjiulong.png"),
        },
        {
          src: require("@/assets/company/lcy.png"),
        },
        {
          src: require("@/assets/company/nbzhoushan.png"),
        },
      ],
      imgList2: [
        {
          src: require("@/assets/company/gangxing.png"),
        },
        {
          src: require("@/assets/company/dongnan.png"),
        },
        {
          src: require("@/assets/company/fanzhong.png"),
        },
        {
          src: require("@/assets/company/bcCompany.png"),
        },
        {
          src: require("@/assets/company/zsgyCompany.png"),
        },
        {
          src: require("@/assets/company/zjzwyCompany.png"),
        },
        {
          src: require("@/assets/company/nbgtCompany.png"),
        },
        {
          src: require("@/assets/company/kyCompany.png"),
        },
        {
          src: require("@/assets/company/KSMR.png"),
        },
        {
          src: require("@/assets/company/zsj.png"),
        },
        {
          src: require("@/assets/company/logistics.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less">
.swiper_view {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        width: 300px;
        height: 150px;
        margin-right: 10px;
        line-height: 120px;
        color: #fff;
        text-align: center;
        font-size: 30px;
      }
    }
  }
}
</style>
