<template>
  <div class="aboutWeTag">
    <div class="screen_height" id="aboutWe">
      <div class="about_left">
        <div class="header_title">
          <span style="color: #f13939; margin-right: 30px">关于</span
          ><span style="color: white">我们</span>
        </div>
        <div style="font-size: 15px; color: #f13939; margin-top: 20px">
          About us
        </div>
        <div class="about_detail">
          <p></p>
          <div class="title_detail">
            我司致力于服务全国各口岸的报关、物流及外贸企业。通过科技手段为企业赋能，提效降费。我司自主研发的专利及各知识产权多达30项，开发的产品项目有单一窗口辅助工具、F1报关ERP系统、工单系统、报关数据一键录入、放舱网等，受到了广大客户的一致好评。目前公司拥有市场推广、客服、技术研发，单证中心等团队近百人，同时在新疆、黑龙江、吉林、辽宁等地建立了单证中心和客服团队，不仅让我们的服务有更好地延伸和拓展，同时也为当地的人才培养、就业等提供了强大的支持。
          </div>
        </div>
        <div class="about_detail">
          <p></p>
          <div class="title_detail">
            公司月平均输单审单量超17万票，服务宁波，嘉兴，台州，义乌，舟山，上海，青岛，威海等地，与近300余家报关行及物流公司保持长期友好的合作关系，我司以打造全国最大的单证服务中心为目标，秉承科技让单证更简单的企业使命，以科技创新、优质服务、诚信高效为理念，不断完善系统、提高技能，服务客户，为物流及报关行业持续输血，共同创造更大的价值。
          </div>
        </div>
      </div>
      <div class="about_right">
        <el-carousel indicator-position="none">
          <el-carousel-item>
            <img src="@/assets/aboutWeImg/about_rightIcon.png" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/aboutWeImg/swiper_1.png" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/aboutWeImg/swiper_2.png" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/aboutWeImg/swiper_3.png" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/aboutWeImg/swiper_4.png" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="company2 screen_height" id="company_state">
      <div class="company2-left">
        <div class="company2-left-title">
          <span style="color: #f13939; margin-right: 30px">公司</span
          ><span>最新动态</span>
        </div>
        <div class="company2-left-cont">科技让单证更简单</div>
      </div>
      <div class="company2-right">
        <div
          class="company2-right-second back"
          v-for="(item, index) in second"
          :key="index"
        >
          <div class="company2-right-time">
            <div class="company2-right-month">
              {{ item.month }}
            </div>
            <div class="company2-right-year">
              {{ item.year }}
            </div>
            <el-divider></el-divider>
          </div>
          <div class="company2-right-cont">
            {{ item.cont }}
          </div>
        </div>
      </div>
    </div>
    <div class="develop_view screen_height">
      <div class="head"></div>
      <div class="transition_view">
        <div v-for="(item, index) in courseData" :key="index">
          <div
            class="chunk"
            :style="chunkPosition(item, index)"
            @click="handleClickYear(index)"
          >
            {{ item.year }}
          </div>
        </div>
        <img src="@/assets/historyImg/flowCurve.png" class="center_line" />
      </div>
      <div class="chunk_detail">
        <h1 class="d_title">{{ courseData[active].title }}</h1>
        <p class="d_tip">
          -<span style="margin-left: 5px">{{ courseData[active].year }}</span>
        </p>
        <p class="d_content">{{ courseData[active].content }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { second,handleCompanyCourse } from '@/utils/common';
export default {
  data() {
    return {
      active: 0,
      second,
      courseData:[],
    };
  },
  computed: {
    chunkPosition: function () {
      return (item, index) => {
        let pos = "";
        switch (item.position) {
          case "top":
            pos = `top:10%;margin-left:${item.margin};`;
            break;
          case "bottom":
            pos = `bottom:10%;margin-left:${item.margin};`;
            break;
        }

        if (this.active === index) {
          pos += `width:120px;height:50px;line-height:50px;font-size:26px;color:#821818;backgroundImage:url(${require("@/assets/historyImg/activeImg.png")});`;
        }
        return pos;
      };
    },
  },
  created(){
    // 获取公司历程信息
    this.courseData = handleCompanyCourse('pc')
  },
  methods: {
    handleClickYear(index) {
      let detailView = document.getElementsByClassName("chunk_detail");
      this.active = index;
      detailView[0].style.setProperty("animation", "none");
      setTimeout(() => {
        detailView[0].style.setProperty("animation", "fadeinR 1.3s");
      }, 100);
    },
  },
};
</script>
<style lang="less">
.el-carousel__indicator .is-active .el-carousel__button {
  background-color: red !important;
}
</style>
<style scoped lang="less">
@media screen and (min-width: 1600px) {
  #aboutWe {
    background-image: url(@/assets/aboutWeImg/aboutBg.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px 15%;
    .about_left {
      width: 55%;
      .header_title {
        font-size: 32px;
        font-weight: 700;
      }
      .about_detail {
        min-height: 200px;
        margin-top: 30px;
        p {
          width: 50px;
          height: 10px;
          background-color: #f13939;
          margin-bottom: 20px;
        }
        .title_detail {
          font-size: 18px;
          line-height: 30px;
          color: white;
        }
      }
    }
    .about_right {
      width: 40%;
      flex: 1;
      /deep/.el-carousel {
        width: 90%;
        height: 90%;
      }
      /deep/.el-carousel__item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /deep/.el-carousel__container {
        height: 500px;
      }
    }
  }
  .develop_view {
    background-image: url(@/assets/historyImg/fillBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 10;
    .head {
      width: 80%;
      height: 10%;
      margin: 3% auto 0;
      background-image: url(@/assets/historyImg/head_title.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .transition_view {
      height: 30%;
      position: relative;
      z-index: 100;
      margin-top: 2%;
      .chunk {
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        background: url(@/assets/historyImg/chunkBg.png);
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        font-size: 24px;
        position: absolute;
        z-index: 999;
        border-radius: 5px;
      }
      .center_line {
        height: 20%;
        width: 100%;
        position: absolute;
        top: 44%;
        object-fit: cover;
      }
    }
    .chunk_detail {
      width: 50%;
      margin: auto;
      color: white;
      .d_title {
        margin-top: 2%;
        font-size: 24px;
        font-weight: 700;
      }
      .d_tip {
        margin-top: 2%;
        font-size: 18px;
        font-weight: 500;
      }
      .d_content {
        font-size: 14px;
        margin-top: 2%;
        line-height: 20px;
      }
    }
  }
  .company1 {
    height: 500px;
    color: #a90f11;
    padding: 30px 100px;
    font-weight: 600;
    .company1-title {
      margin-top: 15px;
      font-size: 28px;
    }
  }

  .company2 {
    height: 500px;
    display: flex;
    justify-content: space-around;
    padding: 20px 15%;
    background-image: url(../assets/newcompanyback@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .company2-left {
    margin-top: 80px;
    width: 30%;
    display: flex;
    flex-direction: column;
    .company2-left-title {
      font-size: 32px;
      font-weight: 800;
      color: #2a3447;
    }
    .company2-left-cont {
      font-size: 18px;
      font-weight: 800;
      color: #2f3b51;
      margin-top: 20px;
    }
  }

  .company2-right {
    flex: 1;
    padding: 20px 0 0 100px;
  }
  .back {
    width: 80%;
    height: 100px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
  }
  .company2-right-time {
    width: 17%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /deep/.el-divider--horizontal {
      height: 8px;
      background-color: white;
      margin: 10px 0 0 0;
    }
  }
  .company2-right-month {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #bc0c0c;
  }
  .company2-right-year {
    font-size: 20px;
    color: #bc0c0c;
  }
  .company2-right-cont {
    font-size: 18px;
    line-height: 25px;
    width: 80%;
  }
  .company2-right-second {
    background-color: white;
    background-size: cover;
    /deep/.el-divider--horizontal {
      height: 5px;
      background-color: #bc0c0c;
      margin: 10px 0 0 0;
    }
  }
  .company2-right-second:hover {
    color: #fff;
    background-image: url(../assets/company2right@2x.png);
    background-size: cover;
    .company2-right-month,
    .company2-right-year {
      color: white;
    }
    /deep/.el-divider--horizontal {
      height: 5px;
      background-color: white;
      margin: 10px 0 0 0;
    }
  }
  .second-time {
    width: 100px;
    margin: 8px;
    color: #bc0c0c;
  }
  .second-cont {
    margin: 0.2rem 0.35rem 0 0;
    font-size: 0.16rem;
    font-weight: 800;
    color: #333333;
  }
}
@media screen and (min-width: 960px) and (max-width: 1600px) {
  #aboutWe {
    background-image: url(@/assets/aboutWeImg/aboutBg.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px 10%;
    .about_left {
      width: 55%;
      overflow: auto;
      .header_title {
        font-size: 32px;
        font-weight: 700;
      }
      .about_detail {
        min-height: 200px;
        margin-top: 30px;
        p {
          width: 50px;
          height: 10px;
          background-color: #f13939;
          margin-bottom: 20px;
        }
        .title_detail {
          font-size: 16px;
          line-height: 30px;
          color: white;
        }
      }
    }
    .about_left::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .about_right {
      width: 40%;
      flex: 1;
      /deep/.el-carousel__item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /deep/.el-carousel__container {
        height: 500px;
      }
    }
  }
  .develop_view {
    background-image: url(@/assets/historyImg/fillBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 10;
    .head {
      width: 80%;
      height: 10%;
      margin: 3% auto 0;
      background-image: url(@/assets/historyImg/head_title.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    .transition_view {
      height: 30%;
      position: relative;
      z-index: 100;
      margin-top: 2%;
      .chunk {
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        background: url(@/assets/historyImg/chunkBg.png);
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        font-size: 24px;
        position: absolute;
        z-index: 999;
        border-radius: 5px;
      }
      .center_line {
        height: 20%;
        width: 100%;
        position: absolute;
        top: 44%;
        object-fit: cover;
      }
    }
    .chunk_detail {
      width: 50%;
      margin: auto;
      color: white;
      .d_title {
        margin-top: 2%;
        font-size: 24px;
        font-weight: 700;
      }
      .d_tip {
        margin-top: 2%;
        font-size: 18px;
        font-weight: 500;
      }
      .d_content {
        font-size: 14px;
        margin-top: 2%;
        line-height: 20px;
      }
    }
  }
  .company1 {
    height: 500px;
    color: #a90f11;
    padding: 30px 100px;
    font-weight: 600;
    .company1-title {
      margin-top: 15px;
      font-size: 28px;
    }
  }

  .company2 {
    height: 500px;
    display: flex;
    justify-content: space-around;
    padding: 20px 10%;
    background-image: url(../assets/newcompanyback@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .company2-left {
    margin-top: 80px;
    width: 30%;
    display: flex;
    flex-direction: column;
    .company2-left-title {
      font-size: 32px;
      font-weight: 800;
      color: #2a3447;
    }
    .company2-left-cont {
      font-size: 18px;
      font-weight: 800;
      color: #2f3b51;
      margin-top: 20px;
    }
  }

  .company2-right {
    flex: 1;
    padding: 20px 0 0 100px;
  }
  .back {
    width: 80%;
    height: 100px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
  }
  .company2-right-time {
    width: 17%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /deep/.el-divider--horizontal {
      height: 8px;
      background-color: white;
      margin: 10px 0 0 0;
    }
  }
  .company2-right-month {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #bc0c0c;
  }
  .company2-right-year {
    font-size: 20px;
    color: #bc0c0c;
  }
  .company2-right-cont {
    font-size: 18px;
    line-height: 25px;
    width: 80%;
  }
  .company2-right-second {
    background-color: white;
    background-size: cover;
    /deep/.el-divider--horizontal {
      height: 5px;
      background-color: #bc0c0c;
      margin: 10px 0 0 0;
    }
  }
  .company2-right-second:hover {
    color: #fff;
    background-image: url(../assets/company2right@2x.png);
    background-size: cover;
    .company2-right-month,
    .company2-right-year {
      color: white;
    }
    /deep/.el-divider--horizontal {
      height: 5px;
      background-color: white;
      margin: 10px 0 0 0;
    }
  }
  .second-time {
    width: 100px;
    margin: 8px;
    color: #bc0c0c;
  }
  .second-cont {
    margin: 0.2rem 0.35rem 0 0;
    font-size: 0.16rem;
    font-weight: 800;
    color: #333333;
  }
}
</style>
