<template>
  <div>
    <div class="head_tab">
      <div class="header">
        <img
          src="../assets/Group@2x.png"
          alt=""
          style="width: 50px; height: 50px; margin: auto 0; margin-left: 15px"
        />
        <div
          style="
            color: #420c0c;
            font-weight: 700;
            margin-left: 15px;
            font-size: 20px;
          "
          @click="returnTop"
        >
          宁波火箭数据科技
        </div>
        <div class="phone">
          <img
            src="../assets/phoneIcon@2x.png"
            alt=""
            style="width: 20px; height: 20px; margin: auto"
          />
          <span style="margin-left: 5px">全国服务热线：</span>
        </div>
        <div style="display: flex;flex-shrink: 0;flex-wrap: wrap;">
          <div class="phone">13429380909</div>
          <div class="phone">13685859945</div>
        </div>
        <div style="display: flex;flex-shrink: 0;flex-wrap: wrap;">
          <div class="phone">0574-27682901</div>
          <div class="phone">0574-27836910</div>
        </div>
      </div>
      <ul class="ul_tab">
        <a href="#home" :class="{ active: selectedTab === 'carousel' }"
          ><li class="li_tag">首页</li></a
        >
        <a href="#productTag"
          ><li
            class="li_tag"
            :class="{ active: selectedTab === 'product_all' }"
          >
            产品中心
          </li></a
        >
        <a href="#guide_view"
          ><li class="li_tag" :class="{ active: selectedTab === 'FCWTag' }">
            放舱网
          </li></a
        >
        <a href="#aboutWe"
          ><li class="li_tag" :class="{ active: selectedTab === 'aboutWeTag' }">
            关于我们
          </li></a
        >
        <a href="#contact_us"
          ><li
            class="li_tag"
            :class="{ active: selectedTab === 'about_title' }"
          >
            联系我们
          </li></a
        >
      </ul>
    </div>
    <OfficialWebsite></OfficialWebsite>
    <ProductCenter></ProductCenter>
    <PcFCWView></PcFCWView>
    <company-view></company-view>
    <CollaborationFirm />
    <div class="company-title">
      与近300家报关行及物流公司保持长期友好合作的关系，我司以打造全国最大的单证服务中心为目标。秉承科技让单证更简单的企业使命，以科技创新、优质服务、诚信高效为理念，不断完善系统服务客户，旨在为报关行业减负增效。
    </div>
    <div class="about" id="contact_us">
      <h1 class="about_title">联系我们</h1>
      <div class="us_list_style">
        <div class="us_item">
          <el-image
            :src="us_one"
            class="us_img"
            :preview-src-list="us_one"
          />
          <p class="foot_phone">
            <el-image :src="us_phoneIcon" class="phone_icon"></el-image>
            <span class="cell_phone">18644079547</span>
          </p>
        </div>
        <div class="us_item">
          <el-image
            :src="us_three_big"
            class="us_img"
            :preview-src-list="us_three_big"
          />
          <p class="foot_phone">
            <el-image :src="us_phoneIcon" class="phone_icon"></el-image>
            <span class="cell_phone">13157331003</span>
          </p>
        </div>
      </div>
      <div class="consultBtn">立即咨询</div>
    </div>
    <div class="footer">
      <div class="foot_head">
        <div v-for="(item, index) in footList" :key="index" class="head_item">
          <img :src="item.url" style="width: 50px; height: 50px" />
          <div class="item_right">
            <h2>{{ item.title }}</h2>
            <p>{{ item.state }}</p>
          </div>
        </div>
      </div>
      <div class="foot_content">
        <div class="footer-size">
          <ul>
            <p
              style="font-size: 20px; margin-bottom: 15px; font-family: 'kaiti'"
            >
              关于我们
            </p>
            <li class="li_item"><a href="#home">公司简介</a></li>
            <li class="li_item"><a href="#company_state">公司动态</a></li>
          </ul>
          <ul>
            <p
              style="font-size: 20px; margin-bottom: 15px; font-family: 'kaiti'"
            >
              公司产品
            </p>
            <li class="li_item"><a href="#productTag">F1接单输单系统</a></li>
            <li class="li_item"><a href="#productTag">单证中心</a></li>
            <li class="li_item"><a href="#productTag">放舱网</a></li>
          </ul>
          <ul>
            <p
              style="font-size: 20px; margin-bottom: 15px; font-family: 'kaiti'"
            >
              产品功能入口
            </p>
            <li class="li_item">
              <a href="https://fangcangwang.com/logistics">物流跟踪及订阅</a>
            </li>
            <li class="li_item">
              <a href="https://fangcangwang.com/boxquery">物流实时查询</a>
            </li>
            <li class="li_item">
              <a href="https://fangcangwang.com/shipSubscrib">船期订阅</a>
            </li>
            <li class="li_item">
              <a href="https://fangcangwang.com/advertisement">供应商目录</a>
            </li>
          </ul>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            width: 35%;
            justify-content: space-around;
          "
        >
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
            "
          >
            <p class="codeText">关注微信公众号</p>
            <div class="weixin"></div>
          </div>
          <div style="height: 100%; display: flex; flex-direction: column">
            <p class="codeText">联系方式</p>
            <div class="foot_touch" style="margin-top: 20px">
              <el-image
                :src="phoneIcon"
                lazy
                style="width: 20px; height: 20px"
              ></el-image>
              <div>
                <p style="margin-left: 10px">0574-27682901</p>
                <p style="margin-left: 10px">0574-27836910</p>
              </div>
            </div>
            <div class="foot_touch">
              <el-image
                :src="mobileIcon"
                lazy
                style="width: 20px; height: 20px"
              ></el-image>
              <div>
                <p style="margin-left: 10px">13429380909</p>
                <p style="margin-left: 10px">13685859945</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-extra-info">
        <el-space>
          <a
            href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89VpB9nV&recordcode=33021202002094"
            class="footer-line"
            style="margin-right: 8px"
          >
            <span class="footer-info">浙公网安备 33021202002094号</span>
          </a>
          <a href="https://beian.miit.gov.cn/" class="footer-line">
            <span class="footer-info">浙ICP备2022012639号-1</span>
          </a>
        </el-space>
      </div>
    </div>
    <div class="fixed_pop">
      <el-popover placement="left-start" width="400" trigger="hover">
        <template slot="reference">
          <div class="pop_item">
            <img src="@/assets/phonePopIcon.png" />
            <p>立即咨询</p>
          </div>
        </template>
        <div class="pop_content">
          <div class="pop_content_top">
            <h1 class="pop_title">0574-27682901 0574-27836910</h1>
            <p class="pop_time">7*24小时客服服务热线</p>
          </div>
          <div class="product_view">
            <p>选择下列产品马上在线沟通:</p>
            <ul class="product_list">
              <li>
                <a
                  href="https://wpa.qq.com/msgrd?v=3&uin=1453360582&site=qq&menu=yes&jumpflag=1"
                  >F1接单输单系统</a
                >
              </li>
              <li>
                <a
                  href="https://wpa.qq.com/msgrd?v=3&uin=1453360582&site=qq&menu=yes&jumpflag=1"
                  >放舱网</a
                >
              </li>
              <li>
                <a
                  href="https://wpa.qq.com/msgrd?v=3&uin=1453360582&site=qq&menu=yes&jumpflag=1"
                  >单证中心</a
                >
              </li>
            </ul>
          </div>
        </div>
      </el-popover>
      <div class="pop_item" @click="returnTop">
        <img src="@/assets/returnTop.png" style="width: 25px; height: 25px" />
        <p>回到顶部</p>
      </div>
    </div>
  </div>
</template>

<script>
import OfficialWebsite from "@/views/OfficialWebsite.vue";
import ProductCenter from "@/views/ProductCenter.vue";
import PcFCWView from "@/views/PcFANGCANGWANGView.vue";
import CompanyView from "@/views/CompanyView.vue";
import CollaborationFirm from "@/components/collaboration_firm.vue";
export default {
  name: "HelloWorld",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    OfficialWebsite,
    ProductCenter,
    CompanyView,
    PcFCWView,
    CollaborationFirm,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      observer: "",
      selectedTab: "carousel",
      activeName: "index",
      tabList: ["首页", "产品中心", "放舱网", "关于我们"],
      footList: [
        {
          url: require("@/assets/aboutWeImg/footIcon1.png"),
          title: "信息智能化",
          state: "挖掘智能关联,智能化服务",
        },
        {
          url: require("@/assets/aboutWeImg/footIcon2.png"),
          title: "业务数字化",
          state: "无纸化操作,数字可视化",
        },
        {
          url: require("@/assets/aboutWeImg/footIcon3.png"),
          title: "专业服务",
          state: "专业服务全流程支持",
        },
        {
          url: require("@/assets/aboutWeImg/footIcon4.png"),
          title: "365天",
          state: "单证中心无休服务",
        },
      ],
      phoneIcon: require("@/assets/phoneIcon.png"),
      mobileIcon: require("@/assets/mobileIcon.png"),
      us_one: require("@/assets/us/us_bigImg_one.jpg"),
      us_three_big: [require("@/assets/us/us_bigImg_three.jpg")],
      us_three: require("@/assets/us/us_three.png"),
      us_phoneIcon: require("@/assets/us/us_phoneIcon.png"),
    };
  },
  mounted() {
    // 初始化滚动动画库
    this.$nextTick(() => {
      this.$wow.init();
    });
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this.selectedTab = entry.target._prevClass;
        } else {
          this.selectedTab = "";
        }
      });
    });
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      var sections = document.querySelectorAll(
        ".carousel,.product_all,.FCWTag,.aboutWeTag,.about_title"
      );
      sections.forEach((section) => {
        this.observer.observe(section);
      });
    },
    returnTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped lang="less">
.active {
  color: #bd0303;
  border-bottom: 2.5px solid #c90604;
}
.fixed_pop {
  width: 60px;
  min-height: 20%;
  background-color: #d92e1b;
  border-radius: 30px;
  position: fixed;
  top: 50%;
  right: 2%;
  z-index: 300;
  box-sizing: border-box;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .pop_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px 0;
    img {
      width: 30px;
      height: 30px;
    }
    p {
      margin-top: 5px;
      font-size: 12px;
      color: white;
      opacity: 0.7;
    }
  }
  .pop_item:hover {
    border-radius: 3px;
    background-color: #c50d1b;
    p {
      opacity: 1;
    }
  }
}
.pop_content {
  box-sizing: border-box;
  .pop_title {
    font-size: 22px;
    color: rgb(238, 185, 85);
  }
  .pop_time {
    font-size: 14px;
    color: #b3b2b2;
  }
}
.pop_content_top {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(224, 224, 224);
}
.product_view {
  margin-top: 20px;
  .product_list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 15px;
    color: #b3b2b2;
    li a {
      color: #999;
      text-decoration: none;
    }
    li a:hover {
      color: #4daad1;
    }
  }
}

.header {
  display: flex;
}
.phone {
  font-size: 14px;
  color: #a90606;
  font-weight: 600;
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.head_tab {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: right;
  background-color: #ffffff;
  box-shadow: 1px 1px 20px 1px #6c7fa33d;
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: fixed;
  top: 0px;
  z-index: 100;
  .ul_tab {
    display: flex;
    a {
      color: rgb(66, 12, 12);
      text-decoration: none;
    }
    .li_tag {
      padding: 0px !important;
      margin-right: 10px;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      display: inline-block;
      list-style: none;
      min-width: 80px;
      font-size: 16px;
      font-weight: 700;
      position: relative;
      text-align: center;
    }
    .li_tag:hover {
      color: #bd0303;
      border-bottom: 2.5px solid #c90604;
    }
  }
}
.about {
  width: 100%;
  height: 500px;
  background-image: url(../assets/lianxiback@2x.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0;
  .consultBtn {
    width: 300px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    font-size: 28px;
    background: #e00f0f;
    opacity: 1;
    border: 1px solid #e30000;
    border-radius: 25px 0 25px 0;
    margin-top: 20px;
    box-shadow: 10px 0 5px 0px rgba(240, 72, 72, 0.856);
  }
  .about_title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px;
  }
  .us_list_style {
    width: 40%;
    min-width: 500px;
    height: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .us_item {
      width: 30%;
      min-width: 300px;
      height: 100%;
      background-image: url(@/assets/us/contactUs_bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 15px;
      box-shadow: 2px 0px 9px 0px #25549b66;
      .us_img {
        width: 100%;
        height: 50%;
      }
      .foot_phone {
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .cell_phone {
          font-size: 25px;
          font-weight: 600;
          color: #33488f;
        }
        .phone_icon {
          width: 30px;
          height: 30px;
          margin: auto 25px;
        }
      }
    }
    .us_item:hover {
      transform: translateY(-5px); /* 向上偏移一定距离 */
      transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    }
  }
}
.footer {
  background: #141b1e;
  font-size: 16px;
  color: #fff;
  min-height: 400px;
  position: relative;
  .foot_head {
    margin: auto;
    display: flex;
    justify-content: space-around;
    background-color: #1e282d;
    padding: 1% 10%;
    box-sizing: border-box;
    .head_item {
      width: 23%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      .item_right {
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 10px;
        h2 {
          font-size: 20px;
          font-weight: 400;
        }
        p {
          margin-top: 8px;
          font-size: 10px;
        }
      }
    }
    .head_item:hover {
      background-color: rgb(36, 35, 35);
    }
  }
  .foot_content {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .codeText {
      font-size: 20px;
      font-family: "kaiti";
    }
    .footer-size {
      display: flex;
      width: 30%;
      justify-content: space-between;
      margin: 0 0 0 10%;
      .li_item {
        opacity: 0.6;
      }
      ul li {
        margin-top: 12px;
      }
      a {
        color: white;
        text-decoration: none;
      }
      ul li a:hover {
        color: rgb(0, 153, 255);
      }
    }
    .foot_touch {
      margin: 12px 0;
      font-size: 18px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .weixin {
      width: 95px;
      height: 95px;
      margin-top: 20px;
      background-image: url(../assets/erweima.png);
      background-size: cover;
    }
  }
  .footer-extra-info {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    .footer-line {
      display: inline-block;
      text-decoration: none;
      height: 20px;
      line-height: 20px;
      &:hover {
        opacity: 0.9;
      }
    }
    .footer-info {
      color: #ffffff;
    }
  }
}
</style>
