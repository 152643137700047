<template>
  <div id="home">
    <div style="position: relative; height: 4.6rem">
      <div class="offocoal">
        <div class="header_title_box">
          <p class="offocal_title_tip">全国最大的单证服务中心之一</p>
          <p class="offocal_title">宁波火箭数据科技有限公司</p>
        </div>
      </div>
      <div class="swiper_box">
        <el-carousel indicator-position="none">
          <el-carousel-item>
            <div class="main_MobileSwiperOne"></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="main_MobileSwiperTwo"></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="main_MobileSwiperTree"></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="core">
      <div class="core1">
        <div class="core1-top">核心业务</div>
        <div class="core1-middle">CORE BUSINESS</div>
      </div>
      <div class="detailCont">
        <div class="details" v-for="(item, index) in tabContent" :key="index">
          <div class="contentIcon">
            <div style="color: #fff; margin: 0.15rem 0.4rem 0.22rem">
              <div
                style="font-size: 0.4rem; font-weight: 600; margin-top: 0.15rem"
              >
                {{ item.title }}
              </div>
              <div style="font-size: 0.16rem; margin-top: 0.1rem">
                {{ item.title1 }}
              </div>
            </div>
          </div>
          <div class="busines_mudule_content">
            <img
              :src="item.imgUrl"
              class="busines_l_img"
              v-if="item.imgPosition === 'left'"
            />
            <div style="margin: 0.3rem auto 0">
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 0.3rem; height: 0.2rem; margin-top: 0.06rem"
                />
                <div class="busines_l_title">{{ item.cont.cont1 }}</div>
              </div>
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 0.3rem; height: 0.2rem; margin-top: 0.06rem"
                />
                <div class="busines_l_title">{{ item.cont.cont2 }}</div>
              </div>
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 0.3rem; height: 0.2rem; margin-top: 0.06rem"
                />
                <div class="busines_l_title">{{ item.cont.cont3 }}</div>
              </div>
              <div class="duiIcon">
                <img
                  :src="item.cont.imgUrl"
                  alt=""
                  style="width: 0.3rem; height: 0.2rem; margin-top: 0.06rem"
                />
                <div class="busines_l_title">{{ item.cont.cont4 }}</div>
              </div>
            </div>
            <img
              :src="item.imgUrl"
              class="busines_l_img"
              v-if="item.imgPosition === 'right'"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; margin-top: 10px">
      <div class="Icon">
        <div v-for="(item, index) in productSign" :key="index" class="Icon-all">
          <img
            :src="item.imgUrl"
            alt=""
            style="width: 0.84rem; height: 0.74rem"
          />
          <div class="Icon-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="producks">
      <div>
        <div class="producks-top">我们的优势</div>
        <div class="producks-middle">PRODUCKS AND BUSINESS</div>
      </div>
      <div class="producks2">
        <div
          class="producks2-cont"
          v-for="(item, index) in producks"
          :key="index"
        >
          <img :src="item.imgUrl" alt="" class="producks2-cont-icon" />
          <div class="producks2-cont-title">
            <div class="producks2-cont-title1">{{ item.title }}</div>
            <div>{{ item.english }}</div>
            <div
              style="
                margin: 0.1rem 0.7rem 0 0;
                font-size: 0.22rem;
                color: #242b32;
                line-height: 20px;
              "
            >
              {{ item.cont }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="choose">
      <div style="width: 8.2rem; margin: 0 auto; padding: 5px 0">
        <div class="choose1">
          <div class="choose1-us">为什么选择我们?</div>
        </div>
        <div class="choose2">
          深厚的技术积累，优质的产品服务和对行业深入的专业分析及判断，
          结合我司自主研发的专利系统致力并服务于全国各口岸的报关、物流及外贸企业，持续为企业赋能，提效降费，助力企业在行业内不断领先发展。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { productSign, tabContent } from "@/utils/common";
export default {
  data() {
    return {
      productSign,
      tabContent,
      producks: [
        {
          title: "流程规范化",
          imgUrl: require("../assets/wuzhihua@2x.png"),
          english: "Process Standardization",
          cont: "业务规范，数据交换规范",
        },
        {
          title: "工作协同化",
          imgUrl: require("../assets/anquan@2x.png"),
          english: "Work Collaboration",
          cont: "打破孤岛，协同链接",
        },
        {
          title: "信息智能化",
          imgUrl: require("../assets/zhuanli@2x.png"),
          english: "Information Intelligence",
          cont: "挖掘数据关联，智能化服务业务数字化",
        },
        {
          title: "业务数字化",
          imgUrl: require("../assets/zidong@2x.png"),
          english: "Business Digitization",
          cont: "无纸化操作，数据可视化",
        },
        {
          title: "大数据共享化",
          imgUrl: require("../assets/kuozeng@2x.png"),
          english: "Biq data Sharing",
          cont: "流程内共享，私密化服务",
        },
        {
          title: "海关AEO认证",
          imgUrl: require("../assets/fuwu@2x.png"),
          english: "Customs AEO Certification",
          cont: "全流程监控，助力AEO认证",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.busines_mudule_content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .busines_l_img {
    width: 3rem;
    height: 3rem;
  }
  .busines_l_title {
    color: #333333;
    font-size: 12px;
    margin: 5px 0 0 10px;
    line-height: 20px;
  }
}
/deep/.el-carousel--horizontal {
  height: 100%;
}
/deep/.el-carousel__container {
  height: 100%;
}
.Icon {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0.23rem 0;
  margin: auto;
  background-color: blue;
  background: linear-gradient(#f0d6d6 14%, #ffffff 40%);
  box-shadow: 0.16rem 0.02rem 0.16rem 0.02rem rgba(136, 158, 190, 0.25);
  .Icon-all {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 5px;
  }
  .Icon-title {
    font-size: 0.12rem;
    font-weight: 600;
    color: #c20d0e;
  }
}
.offocoal {
  width: 100%;
  min-height: 3rem;
  background-image: url(../assets/offocoal1back@2x.png);
  background-size: 100% 3rem;
  text-align: center;
  overflow: hidden;
  .header_title_box {
    margin-top: 10px;
    .offocal_title_tip {
      color: #a30808;
      font-size: 16px;
    }
    .offocal_title {
      color: #8e171f;
      font-weight: 700;
      margin-top: 10px;
      font-size: 0.5rem;
    }
  }
}
.swiper_box {
  width: 14.2rem;
  height: 3rem;
  color: #fff;
  position: absolute;
  top: 1.54rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.core1-top {
  margin-top: 8px;
  height: 0.36rem;
  line-height: 0.36rem;
  text-align: center;
  font-size: 0.4rem;
  font-weight: 800;
  color: #333333;
}
.core1-middle {
  line-height: 0.24rem;
  text-align: center;
  font-size: 0.14rem;
  color: #999999;
  margin-top: 0.1rem;
}
.core1-bottom {
  width: 8.5rem;
  font-size: 0.16rem;
  line-height: 20px;
  color: #999999;
  margin: 0.1rem auto 0;
}
.detailCont {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 0.32rem;
}
.details {
  min-height: 5rem;
  box-shadow: 0px 4px 8px 0px rgba(125, 25, 25, 0.25);
  margin-top: 0.2rem;
}
.contentIcon {
  height: 1.3rem;
  display: flex;
  background: linear-gradient(131deg, #f27574 9%, #cd252d 76%, #cd252d 100%);
  box-shadow: 0px 0.025rem 0.11rem 0px rgba(150, 6, 6, 0.25);
}
.duiIcon {
  width: 5.2rem;
  display: flex;
  align-items: center;
}
.producks {
  width: 100%;
  margin: 20px auto 0;
  padding: 10px 0;
  background-image: url(../assets/youshiback@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.producks-top {
  height: 0.48rem;
  font-size: 0.35rem;
  font-weight: 600;
  color: #222528;
  line-height: 0.48rem;
  text-align: center;
  padding-top: 0.2rem;
}
.producks-middle {
  height: 0.15rem;
  font-size: 0.16rem;
  font-weight: 500;
  color: #999999;
  line-height: 0.15rem;
  text-align: center;
  padding-top: 0.2rem;
}
.producks2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.producks2-cont {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.35rem;
  transform: translateX(10%);
}
.producks2-cont-icon {
  width: 0.84rem;
  height: 0.84rem;
  margin: 0.3rem 0.3rem 0 0.5rem;
}
.producks2-cont-title {
  margin: 0.3rem 0.3rem 0 0;
}
.producks2-cont-title1 {
  font-size: 0.3rem;
  font-weight: 800;
  color: #242b32;
  margin-bottom: 5px;
}
.producks2-cont-title2 {
  font-size: 0.26rem;
  font-weight: 800;
  color: #242b32;
}
.choose {
  width: 100%;
  color: #ffffff;
  background-image: url(../assets/chooseBack@2x.png);
  background-size: 9.2rem 2.86rem;
  margin: auto;
}
.choose1 {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #ffffff;
}
.choose1-us {
  font-size: 0.32rem;
  margin: 0.6rem 0;
}
.choose1-btn {
  width: 1.6rem;
  height: 0.5rem;
  margin-top: 0.6rem;
  margin-right: 0.5rem;
  background: #ff8126;
  border: #ff8126;
  color: #fff;
  font-size: 0.24rem;
  font-weight: 500;
}
.choose2,
.choose3 {
  font-size: 0.22rem;
  margin-bottom: 0.3rem;
  line-height: 20px;
}
</style>
