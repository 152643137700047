import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/productTag',
        name: 'home',
        component: Home,
    },
    {
        path: '/guide_view',
        name: 'home',
        component: Home,
    },
    {
        path: '/aboutWe',
        name: 'home',
        component: Home,
    },
    {
        path: '/company_state',
        name: 'home',
        component: Home,
    },
    {
        path: '/contact_us',
        name: 'home',
        component: Home,
    },
];

const router = new VueRouter({
    // mode: "history", // 部署到oss需要注释掉
    base: process.env.BASE_URL,
    routes,
});

export default router;
