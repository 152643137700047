<template>
  <div class="FCWTag">
    <div id="guide_view">
      <div
        v-for="(item, index) in guideTitle"
        :key="index"
        class="guide_centerItem"
        @click="openWindowUrl(item.link)"
      >
        <div class="simple_box">
          <img :src="item.url" class="guide_image" />
          <p class="guide_fs">{{ item.title }}</p>
        </div>
        <div class="hover_show_box">
          <p class="hover_detail_top">{{ item.detail }}</p>
          <p class="hover_detail_btn">点击了解 ></p>
        </div>
      </div>
    </div>
    <div class="tail_detail">
      <div class="detail_left">
        <h1 class="detail_title">全程物流跟踪</h1>
        <el-divider></el-divider>
        <p class="detail_content">全域管理预测 周期预测 支持网页版</p>
        <p class="detail_content">小程序微信订阅提醒</p>
        <p class="detail_content">可视化物流跟踪 实时监控 操作</p>
        <p class="detail_content">简单异常提醒 随时随地使用</p>
        <div class="detail_opear" @click="toTailAfterView">开始使用</div>
      </div>
      <div class="detail_right">
        <img
          src="@/assets/fcw/tailAfterIcon.png"
          class="wow animate__animated animate__fadeInDown"
          style="display: inline-block"
        />
      </div>
    </div>
    <div class="tail_detail">
      <div class="detail_right" style="justify-content: flex-start">
        <img
          src="@/assets/fcw/realQueryTimeIcon.png"
          class="wow animate__animated animate__fadeInDown"
          style="display: inline-block"
        />
      </div>
      <div class="detail_left">
        <h1 class="detail_title">物流实时查询</h1>
        <el-divider></el-divider>
        <p class="detail_content">
          船舶物流实时位置一键可查，航行轨迹全程可视化
        </p>
        <p class="detail_content">
          数据同步EDI,数据齐全精准定位 节点可视 加速数字化转型
        </p>
        <div class="detail_opear" @click="toQueryView">立即查询</div>
      </div>
    </div>
    <div class="tail_detail">
      <div class="detail_left">
        <h1 class="detail_title">船期订阅</h1>
        <el-divider></el-divider>
        <p class="detail_content">实时更新自动预警</p>
        <p class="detail_content">无需登陆船公司，船期箱货一键跟踪</p>
        <p class="detail_content">节点实时推送，船期箱货全程无忧</p>
        <div class="detail_opear" @click="toSubscriptView">开始使用</div>
      </div>
      <div class="detail_right">
        <img
          src="@/assets/fcw/subscriptionIcon.png"
          class="wow animate__animated animate__fadeInDown"
          style="display: inline-block"
        />
      </div>
    </div>
    <div class="tail_detail">
      <div class="detail_right" style="justify-content: flex-start">
        <img
          src="@/assets/fcw/promotionIcon.png"
          class="wow animate__animated animate__fadeInDown"
          style="display: inline-block"
        />
      </div>
      <div class="detail_left">
        <h1 class="detail_title">供应商推广</h1>
        <el-divider></el-divider>
        <p class="detail_content">多元方式助力推广</p>
        <p class="detail_content">货代/车队/报关行</p>
        <p class="detail_content">节点实时推送，船期箱货全程无忧</p>
        <div class="detail_opear" @click="toPromotionView">开始使用</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headerLeftList: [
        "火箭科技推出全新产品“放舱网",
        "为进出口企业和物流公司提供全流程物流跟踪服务",
        "准确、高效的物流信息追踪和管理平台",
        "助力企业降低物流成本，提升物流效率",
      ],
      guideTitle: [
        {
          title: "物流跟踪",
          url: require("@/assets/fcw/guideQueryIcon.png"),
          detail: "节点实时推送,箱货查询全程无忧",
          link: "https://fangcangwang.com/logistics",
        },
        {
          title: "船期订阅",
          url: require("@/assets/fcw/guideSubIcon.png"),
          detail: "一键订阅跟踪,实时查询,自动预警",
          link: "https://fangcangwang.com/shipSubscrib",
        },
        {
          title: "物流实时查询",
          url: require("@/assets/fcw/guideRealTimeIcon.png"),
          detail: "一键可查,数据可视,数据同步EDI",
          link: "https://fangcangwang.com/logistics",
        },
        {
          title: "供应商目录",
          url: require("@/assets/fcw/guideProviderIcon.png"),
          detail: "货代/车队/报关行,多元方式助力推广",
          link: "https://fangcangwang.com/advertisement",
        },
        {
          title: "归大师",
          url: require("@/assets/fcw/guideHsIcon.png"),
          detail: "HS编码智能查询，归类不求人",
          link: "https://fangcangwang.com/classAnalysis",
        },
      ],
    };
  },
  methods: {
    openWindowUrl(url) {
      window.open(url, "_blank");
    },
    toTailAfterView: () => {
      window.open("https://fangcangwang.com/logistics", "_blank");
    },
    toQueryView: () => {
      window.open("https://fangcangwang.com/boxquery", "_blank");
    },
    toSubscriptView: () => {
      window.open("https://fangcangwang.com/shipSubscrib", "_blank");
    },
    toPromotionView: () => {
      window.open("https://fangcangwang.com/advertisement", "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 1600px) {
  #guide_view {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 30px 15%;
    // box-shadow: 1px 1px 10px #e7ecf3;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 10%,
      #ffffff 44%,
      #ffffff 100%
    );
    .guide_centerItem {
      width: 18%;
      height: 90px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      background-color: white;
      border-radius: 8px;
      position: relative;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .simple_box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
      }
      .hover_show_box {
        height: 100%;
        background-image: url(@/assets/fcw/hoverBg.png);
        background-size: 100% 100%;
        // background-position: 86%;
        position: absolute;
        top: 0;
        opacity: 0;
        padding: 10px 0 0 20px;
        box-sizing: border-box;
        color: white;
        .hover_detail_top {
          width: 50%;
          line-height: 16px;
        }
        .hover_detail_btn {
          width: 50%;
          margin-top: 8px;
          font-weight: 600;
        }
      }
      .guide_image {
        width: 75px;
        height: 75px;
      }
      .guide_fs {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .guide_centerItem:hover {
      width: 18%;
      box-shadow: none;
      transition: all 0.3s ease-in-out;
      .simple_box {
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }
      .hover_show_box {
        width: 100%;
        opacity: 1;
        transition: all 1s ease-in-out;
      }
    }
  }
  .tail_detail {
    height: 400px;
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail_left {
      width: 45%;
      .detail_title {
        font-size: 22px;
        font-weight: 700;
      }
      .detail_content {
        margin: 20px 0;
        font-size: 18px;
        line-height: 20px;
      }
      .detail_content:hover {
        color: #bc0c0c;
      }
      .detail_opear {
        border: 1px solid #bc0c0c;
        color: #bc0c0c;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        width: 120px;
        height: 40px;
        line-height: 40px;
      }
      .detail_opear:hover {
        color: white;
        background-color: #bc0c0c;
      }
      /deep/.el-divider {
        display: block;
        width: 50px;
        height: 8px;
        background-color: #bc0c0c;
        margin: 10px 0 0 0;
      }
    }
    .detail_right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 500px;
        height: 100%;
        animation: fadeinT 2s;
      }
    }
  }
  .tail_detail:nth-child(3n) {
    background-color: #f3f6f9;
  }
  .tail_detail:nth-child(5n) {
    background-color: #f3f6f9;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1600px) {
  #guide_view {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 30px 15%;
    // box-shadow: 1px 1px 10px #e7ecf3;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 10%,
      #ffffff 44%,
      #ffffff 100%
    );
    .guide_centerItem {
      width: 20%;
      height: 70px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      background-color: white;
      border-radius: 8px;
      position: relative;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .simple_box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
      }
      .hover_show_box {
        height: 100%;
        background-image: url(@/assets/fcw/hoverBg.png);
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        opacity: 0;
        padding: 5px 0 0 20px;
        box-sizing: border-box;
        color: white;
        .hover_detail_top {
          width: 50%;
          line-height: 15px;
        }
        .hover_detail_btn {
          width: 50%;
          margin-top: 3px;
          font-weight: 600;
        }
      }
      .guide_image {
        width: 75px;
        height: 75px;
      }
      .guide_fs {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .guide_centerItem:hover {
      width: 20%;
      box-shadow: none;
      transition: all 0.3s ease-in-out;
      .simple_box {
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }
      .hover_show_box {
        width: 100%;
        opacity: 1;
        transition: all 1s ease-in-out;
      }
    }
  }
  .tail_detail {
    height: 400px;
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail_left {
      width: 45%;
      .detail_title {
        font-size: 22px;
        font-weight: 700;
      }
      .detail_content {
        margin: 20px 0;
        font-size: 18px;
        line-height: 20px;
      }
      .detail_content:hover {
        color: #bc0c0c;
      }
      .detail_opear {
        border: 1px solid #bc0c0c;
        color: #bc0c0c;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        width: 120px;
        height: 40px;
        line-height: 40px;
      }
      .detail_opear:hover {
        color: white;
        background-color: #bc0c0c;
      }
      /deep/.el-divider {
        display: block;
        width: 50px;
        height: 8px;
        background-color: #bc0c0c;
        margin: 10px 0 0 0;
      }
    }
    .detail_right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 350px;
        height: 100%;
        animation: fadeinT 2s;
      }
    }
  }
  .tail_detail:nth-child(3n) {
    background-color: #f3f6f9;
  }
  .tail_detail:nth-child(5n) {
    background-color: #f3f6f9;
  }
}
@media screen and (max-width: 1100px) {
  #guide_view {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .guide_centerItem {
      width: 20%;
      height: 50px;
      display: flex;
      box-sizing: border-box;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      border-radius: 8px;
      .simple_box{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .guide_image {
        width: 40px;
        height: 40px;
      }
      .guide_fs {
        font-size: 12px;
        font-weight: 700;
      }
    }
    .hover_show_box{
      display: none;
    }
  }
  .tail_detail {
    padding: 15px;
    display: flex;
    align-items: center;
    .detail_left {
      width: 45%;
      .detail_title {
        font-size: 18px;
        font-weight: 700;
      }
      .detail_content {
        margin: 20px 0;
        font-size: 13px;
        line-height: 17px;
      }
      .detail_opear {
        border: 1px solid #bc0c0c;
        color: #bc0c0c;
        border-radius: 5px;
        text-align: center;
        width: 100px;
        height: 30px;
        line-height: 30px;
      }
      .detail_opear:hover {
        color: white;
        background-color: #bc0c0c;
      }
      /deep/.el-divider {
        display: block;
        width: 50px;
        height: 8px;
        background-color: #bc0c0c;
        margin: 10px 0 0 0;
      }
    }
    .detail_right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
  .tail_detail:nth-child(3n) {
    background-color: #f3f6f9;
  }
  .tail_detail:nth-child(5n) {
    background-color: #f3f6f9;
  }
}
</style>
